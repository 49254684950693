const data = [
  {
    id: 1,
    ruName: "Аббас Мирза",
    faName: "عباس میرزا",
    enDate: "1789-1833",
    faDate: "1212-1168",
    description: `<span>عباس میرزا فرزند فتحعلی شاه قاجارو فرمانده ی سپاه ایران در جنگ های ده ساله ی ایران و روسیه است. فتحعلی 
    شاه در سال دوم سلطنت خود یعنی در سال 1178 ش. عباس میرزا را که در آن زمان
     ده ساله بود، ولیعهد خود کرد و نایب السلطنه لقب داد. شهرت نام وی به
     علت مجاهدت های او در جنگ های ایران و روسیه است. در اواخر سال 1803 م
    . (1182ش.) روس ها به ایروان حمله کردند و عباس میرزا نایب السلطنه که در
     آن زمان فرمانروای آذربایجان بود، به منظور مقابله با روس ها راهی ایروان شد.
     عباس میرزای شانزده ساله به مدت ده سال در برابر نیرو های روس مقاومت کرد. در طول این ده سال
     روس ها چندین سردار عوض کردند و به پشتیبانی لشکرخود فرستادند. ازجمله ی آنها می توان به 
     <span class="link-to-content" onclick="goToPage(68)">سیسیانُف</span> و 
     <span class="link-to-content" onclick="goToPage(61)">یِرملُف</span> اشاره کرد و همچنین نبردهایی از جمله نبرد اصلاندوز، نبرد آچمزین، نبرد
      خانشین بین ایران و روسیه درگرفت و عهدنامه هایی از جمله 
      <span class="link-to-content" onclick="goToPage(40)">گنجه</span>، <span class="link-to-content" onclick="goToPage(39)">رشت</span>، 
      <span class="link-to-content" onclick="goToPage(41)">گلستان</span>، <span class="link-to-content" onclick="goToPage(42)">ترکمانچای</span>; 
      امضا شد . اما ضعف حکومت مرکزی و نیروهای نظامی باعث شدکه نهایتا این جنگ ها با امضای <span class="link-to-content" onclick="goToPage(41)">عهدنامه گلستان</span>; 
      به اتمام برسند. عباس میزرا قبل از مرگ پدر درگذشت و به سلطنت نرسید و پسرش محمد میرزا جانشین وی شد.</span>`,
    imgSrc: ["1/1.jpg", "1/2.jpg", "1/3.jpg", "1/4.jpg"],
    videoSrc: "1/1.mp4",
  },
  {
    id: 2,
    ruName: "Ага Мир Фаттах Табатабаи",
    faName: "آقامیرفتاح طباطبایی",
    enDate: "1794-1852",
    faDate: "1172-1230",
    description: `<span>شخصیت سیاسی-مذهبی صاحب نامی که به واسطه ی خوش خدمتی  به ارتش روسیه در تاریخ روابط ایران و روسیه مطرح شده است. وی فرزند میرزایوسف، عالمی محترم در
     تبریز بود که خاندانش اعتبار و نفوذ زیادی در آن شهر داشتند. در جریان محاصره تبریز توسط قوای روس، آقامیرفتاح 4 گروه شیعی تشکیل داد و به استقبال لشکر روس
      شتافت و درهای شهر را به روی ژنرال اریستُف گشود. این کار وی خیانت بزرگی محسوب شد و ضربه ی بزرگی به حیثیت دولت قاجار زد. خوش خدمتی های وی به دولت
       روسیه قبل و بعد از این واقعه باعث شد که اورا به تفلیس منتقل کنند و تحت حمایت مستقیم ژنرال پاسکویچ قرار بگیرد. روس  ها به آقا میرفتاح قول دادندکه املاک و دارایی های وی در
        ایران توسط سفیر روسیه در دربار قاجار حفاظت شود و مستمری ای به میزان «سالانه دوهزار اشرفی» برایش مقررکردند. در خرداد 1207 آقا میرفتاح به عضویت نهادی نوبنیاد به نام کمیته
         قفقاز درآمد که قرار بود اطلاعات آماری اهالی مسلمان آن منطقه را گردآوری کند و تدابیری برای مدیریت امور آنان همنوا با سیاست های اداری روسیه پیشنهادکند. چندی بعد آقامیرفتاح، طرح پیشنهادی
          خود راکه آراسته به موعظه ای در ترغیب مسلمانان برای تمکین به حکومت تزار بود، به فرمانروای قفقاز ارائه کرد. او در این طرح در کنار درخواست های معین برای برقراری مستمری و دریافت خانه و
           ملک، برقراری مواجب و مستمری دولتی برای علمای موافق این طرح را نیز خواستار شده بود. افزون براین، خواستار آن شده بود که خود، مرجع نهایی و مطلق درمسائل دینی شیعیان قفقاز باشد
            و رابطه و مناسبات او با حکومت در یک چارچوب رسمی تدوین شود. پاسکویچ خواسته های آقامیرفتاح را پذیرفت و افزون بر آن ملکی نیز در تفلیس به او اعطا کرد که
             در سال های بعد به باغ مجتهد موسوم گشت. اماچندی بعد یعنی درسال 1210 ش. ژنرال پاسکویچ که پشتیبان اصلی آقامیرفتاح درحکومت روسی قفقاز بود، به لهستان
              اعزام شد و پس جانشینی بارون روزن تمامی مناسبات برهم ریخت تاجایی که آقامیرفتاح درخواست بازگشت به ایران را داد که از سوی دولت روسیه رد شد و در نهایت در سال 1220ش. به تبریز بازگشت.</span>`,
    imgSrc: [],
    videoSrc: "",
  },
  {
    id: 3,
    ruName: "Ага Мухаммед хан Каджар",
    faName: "آغامحمد خان قاجار",
    enDate: "1741-1797",
    faDate: "1120-1176",
    description: `<span>مؤسس دودمان قاجاریه در ایران است. وی فرزند محمّدحسن‌ خان رئیس طایفه‌ی اشاقه‌باش بود. آنگونه که در تاریخ آمده است وی را اخته کرده بودند، اما به دلیل ترس از آغامحمدخان هیچ مورخی به وضوح دلیل
     را ذکر نکرده است. از نظر برخی از تاریخ نگاران او به دلیل ارتباط با زنان حرمسرای زندیه توسط عادل شاه اخته شده است. روایت دیگردلیل اخته شدن را مشکلات دشمنان با محمدحسن خان پدر
      او و رئیس ایل قاجار می دانند. وی تا قبل ازمرگ کریمخان در دربار زندیه اسیر بود و بلافاصله پس از مرگ او، به سمت تهران عظیمت کرده و در ورامین اعلام سلطنت کرد.
       پادشاهی خونین وی بر ایران همزمان با سطلنت تزار <span class="link-to-content" onclick="goToPage(43)">پاول اول</span> در روسیه بود. در آن زمان با وجود عقب نشینی ارتش روسیه از مناطق اشغالی ایران، آغامحمدخان برای گرفتن انتقام، دستور داد که تمامی اسرای روس تباری که
        در جریان فتح تفلیس به اسارت درآمده بودند قتل عام شوند و تمام روس هایی که در مناطق انزلی، بادکوبه و دربند ساکن شده بودند را به زنجیرکشیده و به تهران بفرستند.
         او در اسفند 1175 مصادف با فوریه 1797 لشگرکشی کرده و وارد قفقاز شد و در ساحل رود ارس اردو زد. طبق توافق با حاکم شوشی (شوشا) مبنی برعدم کشتار در آن
          شهر، شوشی را تسخیرکرد و در آنجا مستقرشد و خود را مهیای لشکرکشی به تفلیس کرد. آغامحمدخان درحالی که در اوج قدرت خود برای حمله به تفلیس قرار داشت، در تاریخ  18
           خرداد  1176 (8 ژوئن 1797 ) توسط دوتن از پیشخدمتان خود کشته شد. وی در هنگام مرگ 57 سال داشت و 18 سال بر ایران سلطنت کرده بود. اما برخی
            منابع دوران جنگ های آغامحمدخان را جزء سلطنت وی نمی دانند و از زمان تاج  گذاری رسمی وی را پادشاه می دانند و این دوران را یک سال قلمداد می کنند. </span>`,
    imgSrc: ["3/1.jpg", "3/2.jfif", "3/3.jpg", "3/4.jpg"],
    videoSrc: "3/1.mp4",
  },
  {
    id: 4,
    ruName: "Алекса́ндр I",
    faName: "الکساندر اول",
    enDate: "1777-1825",
    faDate: "1156-1204",
    description: `<span>تزارالکساندر اول پادشاه روسیه و فرزند بزرگ <span class="link-to-content" onclick="goToPage(43)">پاول اول</span> بود. به حکومت رسیدن وی برابرشد با آغاز
     جنگ های ده ساله ایران و روسیه. برای دولت او منطقه ی قفقاز اهمیت استراتژیکی داشت، به همین خاطر تزار درسال 1803م. (1181ش.) ژنرال <span class="link-to-content" onclick="goToPage(68)">سیسیانُف</span>
      را با اختیارتام به منظور پس گرفتن قفقاز از ایران روانه آن منطقه کرد. در زمان سلطنت وی جنگ میان فرانسه و روسیه نیزدرگرفت،که درنهایت به دلیل تلفات بسیار زیاد نیروهای روسی، تزار الکساندر اول از
       ناپلئون تقاضای آتش بس کرد و در منطقه تیلسیت به دیدار امپراتور فرانسه رفت. امضای 
       <span class="link-to-content" onclick="goToPage(73)">معاهده ی تیلسیت</span> درواقع مساوی شد باخیانت ناپلئون به ایران. در زمان سلطنت وی اصلاحات زیادی در روسیه صورت گرفت از جمله: انحلال
        پلیس مخفی، حذف ممنوعیت سفربه خارج، حذف فرایند سانسور کتابها و .... از جمله مهم ترین اتفاقاتی که در دوران سلطنت وی در روسیه رخ داد <span class="link-to-content" onclick="goToPage(6)">جنگ میهنی</span> و پیروزی تاریخی ارتش روسیه بر قوای ناپلئون بوده است.
         وی پس از 24 سال سلطنت بر روسیه در سن 48 سالگی براثر بیماری درگذشت و برادرش نیکلای اول جانشین او شد.</span>`,
    imgSrc: ["4/2.jpg", "4/3.jpg"],
    videoSrc: "",
  },
  {
    id: 5,
    ruName: "Бороди́нское сраже́ние",
    faName: "نبرد بارادینو",
    enDate: "1812",
    faDate: "1191",
    description: `<span>بارادینو (بارادینو) نبردی است که بین ارتش فرانسه و روسیه در منطقه ی بارادینو در یکصد کیلومتری غرب مسکو درگرفت. طرفین تقریبا  با نیروهای
     برابر وارد جنگ شدند: روس ها به فرماندهی <span class="link-to-content" onclick="goToPage(55)">کوتوزُف</span> 132 هزار نفر  با
      624 توپ و فرانسوی ها 135 هزار نفر با 590 توپ،که در مجموع بیش از 70 هزار نفر از طرفین جان خود را از دست دادند. در روسیه
       بارادینو یک پیروزی روحیه بخش بر دشمن تلقی می شود. پس از این نبرد، علی رغم عقب نشینی روسیه و ورود فرانسوی ها به مسکو، ارتش ناپلئون نتوانست توانایی رزمی و روحیه ی
        جنگندگی سابق خود را حفظ کند. انعکاس رویدادهای قهرمانانه مربوط به این نبرد را می توان در بسیاری از آثار ادبی و هنری روسیه به وضوح
         دید، مانند: رمان حماسی "جنگ و صلح" اثر لف نیکلایویچ تالستوی و "بارادینو" اثر میخائیل یورییویچ لرمانتوف.</span>`,
    imgSrc: ["5/1.jpg", "5/2.jpg", "5/3.jpg", "5/4.jpg"],
    videoSrc: "5/1.mp4",
  },
  {
    id: 6,
    ruName: "Вели́кая оте́чественная война́",
    faName: "جنگ کبیر میهنی ",
    enDate: "1941-1945",
    faDate: "1320-1324",
    description: `<span>جنگ اتحاد جماهیرشوروی بر علیه آلمان فاشیست و متحدانش (مجارستان، ایتالیا، رومانی، اسلواکی، کرواسی، فنلاند و ژاپن). بخشی جدا نشدنی از جنگ جهانی دوم
     که در سالهای (1939-1945) درگرفت. از آنجایی که از نظر زمانی و مکانی مقیاس بالاتری را نسبت به جنگ 1812 در برمی گرفت کلمه ی کبیر به این جنگ اطلاق می شود.
      کلمه ی میهنی از آن جهت انتخاب شده  است که این جنگ در تاریخ روسیه از مهم ترین جنگ ها بر سر آزادی و استقلال وطن به شمار می رود. جنگ در ساعت 4 بامداد
       22 ژوئن 1941( 20 تیر 1320) آغاز شد. در زمان حمله آلمان ارتش عظیمی شامل 5.5 میلیون سرباز، تعداد زیادی تانک، هواپیما و سایرتجهیزات
        نظامی را در مرز <span class="link-to-content" onclick="goToPage(52)">اتحاد جماهیر شوروی سوسیالیستی</span> متمرکز کرده بود. در آن زمان
         ارتش سرخ فقط 2.9 میلیون سرباز در مناطق مرزی داشت و تجهیزات جنگی آنها 2 تا 3 برابرکمتراز آلمان ها بود. اولین مرحله ی جنگ برای اتحاد جماهیر شوروی با خسارات
          سنگین ناشی از محاسبات غلط استراتژیک رهبری کشور همراه بود و این نکته ی قابل توجه نیز وجود دارد که بسیاری از
           فرماندهان با تجربه ارتش سرخ در زمان <span class="link-to-content" onclick="goToPage(69)">تصفیه ی خونین</span> دشمن مردم اعلام شده و کشته
            شده بودند. اما پس از آن شکست، روس ها موفق شدند در چندین نبرد از جمله نبرد مسکو، استالینگراد، لنینگراد، کورسک و دیگر حملات جنگی آلمان ها را شکست دهند. همزمان با این
             پیروزی ها در جبهه دوم در شمال فرانسه متحدان روسیه (انگلستان و آمریکا) پیروز شدند. جنگ در مرزهای شوروی در سال 1944م.  به اتمام رسید. 9 می
              که مصادف با پیروزی ارتش روسیه بر آلمان نازی در جنگ جهانی دوم است، <span class="link-to-content" onclick="goToPage(64)">روز پیروزی</span> نامیده شده و یکی از بزرگترین جشن های ملی-میهنی روسیه
               به شمار می رود. جنگ کبیر میهنی جان 27 میلیون نفر از  نظامیان و شهروندان اتحاد جماهیر شوروی را گرفت. خسارت مادی ناشی از این جنگ بیش از یک سوم ثروت ملی کشور بود.</span>`,
    imgSrc: ["6/1.jpg", "6/2.jpg", "6/3.jpg", "6/4.jpg"],
    videoSrc: "6/1.mp4",
  },
  {
    id: 7,
    ruName: "Степа́н (Стенька) Тимо́феевич Ра́зин",
    faName: "استپان تیموفیویچ رازین",
    enDate: "1630-1671",
    faDate: "1008-1049",
    description: `<span>استپان تیموفیویچ رازین سردار قزاق و رهبرنیروهای قزاق در قیام کشاورزان علیه حکومت مرکزی است. قیام دهقانان به رهبری او در سال 1671م. (1050 ش.) و با خیانت قزاق ها
     از ترس از دست دادن آزادی و خودمختاری، توسط نیروهای تزار سرکوب شد. وی را در 6 ژوئن 1671 (16خرداد 1050) سر بریده و سر او را بر نیزه کردند. او در تاریخ روابط ایران و روسیه به واسطه ی تصمیم به
      فتح قسمت های ایرانی دریای خزردر سال های 1667-1669م. (1046-1048ش.) شهرت دارد. وی با نیروهای خود به سمت شمال ایران حرکت کرد، اما هنگامی که دولت روسیه از این تصمیم باخبرشد، به او دستور بازگشت
       به روسیه را داد. رازین به فرمان تزار توجهی نکرد و این سرپیچی به نبرد ارتش تزار با نیروهای او درآستراخان ختم شد. باوجود کشته شدن بسیاری از نیروهایش، رازین به راه خود ادامه داد و
        شهر های دربند و باکو را به محاصره درآورد، همچنین در شهرهای رشت و فرح آباد (ساری) به قتل و غارت و تجاوز پرداخت. در منابع آمده است که وی پس از آن جهت اعلام وفاداری
         و اخذ اجازه اقامت در ایران نزد شاه سلیمان صفوی رفت، اما شاه برای جلوگیری از جنگ بین ایران و روسیه درخواست وی را ردکرد.
          فلکلور ها و آثار ادبی روسی سخن از عشقی  می گویند که بین رازین و شاه دخت ایرانی بوده است. از جمله ی آنها می توان به شعر " صخره استپان رازین – (Утес Степана Разина" اثر آ.آ. ناوروتسکی و "из-за острова на
           стрежень- از پشت جزیره به سوی رود خروشان "  اثر د.ن. سادونیکوا اشاره کرد. او نهایتا برای اعلام وفاداری به نیروها و سرزمین خود و رود ولگا، معشوقه ی خودرا درحالی که میزان زیادی طلا، جواهرات
            و الماس همچون ملکه به او آویخته بود با دست های بسته به درون ولگا انداخت.</span>`,
    imgSrc: ["7/1.jpg", "7/2.jpg", "7/3.jpg", "7/4.jpg"],
    videoSrc: "7/1.mp4",
  },
  {
    id: 8,
    ruName: "Восстание декабристов",
    faName: "قیام دکابریست ها",
    enDate: "1825",
    faDate: "1204",
    description: `<span>دکابریست ها (دسامبریست ها) انقلابیونی هستندکه در سال 1825م. (1204ش.) علیه خودکامگی و زندگی ارباب رعیتی قیام کردند. وجه تسمیه این قیام رخ دادن آن در ماه دسامبر (به روسی دکابر) است. دکابریست ها
     اکثرا از طبقات بالای جامعه بودند. آنها با ایده ی ساخت جامعه ی دموکراتیک، حذف ارباب رعیتی، برخورداری عموم مردم از حقوق شهروندی، آزادی های مدنی (آزادی بیان، مطبوعات، مذهب و غیره) قیام کردند. پس
      از مرگ الکساندر اول مناقشات زیادی بر سر جانشینی وی درگرفت، چرا که الکساندر در وصیت نامه اش نیکلای برادر کوچک خود را به عنوان جانشین معرفی کرده  بود ولی درباریان معتقد بودند که کنستانتین برادر
       دوم وارث تاج و تخت است. این درشرایطی بود که خود کنستانتین نیز علاقه ای به سلطنت نداشت. در نهایت طبق وصیت الکساندر اول نیکلای اول پادشاه شد و این مساله مهر تاییدی شد بر تصمیم شورش افسران
        ارتش مخالف او. (شورشی که به صورت همزمان در دو شهر سنت پترزبورگ و کی  یف آغاز شد. البته آنها در واقع به دنبال سلطنت کنستانتین نبودند، بلکه به دنبال رسیدن به
             حقوق شهروندی و پایان حکومت تزاری بودند). نهایتا در روز 14 دسامبر  3 هزار سرباز به همراه 30 افسر ارتش در میدان سنات شهرسنت پترزبورگ جمع شدند ولی به دستور
              نیکلای اول بر روی آنها آتش گشودند. از سوی دیگر اطلاعات شورشی که قرار بود به صورت همزمان در شهرکی یف صورت گیرد، فاش
               شد و ظرف چند روز رهبران آن دستگیر شدند. در نتیجه ی این قیام پنج نفر از رهبران را اعدام کردند و مابقی رهبران و 2.5 هزار سرباز به اردوگاه های کار اجباری سیبری تبعید
                شدند ضمن این که درجه نظامی افسران به سرباز تنزل یافت. همسران دکابریست ها که در تاریخ روسیه "دکابریستکا"  نامیده می شوند نیز به صورت داوطلبانه به همراه آن ها به سیبری عزیمت کردند و
                 مشغول به کار اجباری شدند. دکابریست ها در زمان الکساندر دوم مورد عفو قرار گرفتند و آزاد شدند. این واقعه در تاریخ و ادبیات روسیه انعکاس زیادی داشته است. از جمل
                 ه: " Арион-آریون"، " в сибирь- به سوی سیبری" اثر پوشکین، " русские женщины- زنان روسی" اثر نیکراسوف و....</span>`,
    imgSrc: ["8/1.jpg", "8/2.jpg", "8/3.jpg", "8/4.jpg", "8/5.jpg"],
    videoSrc: "8/1.mp4",
  },
  {
    id: 9,
    ruName: "Пе́рвая мирова́я война́",
    faName: "جنگ جهانی اول",
    enDate: "1914-1918",
    faDate: "1293-1297",
    description: `<span>نخستین جنگ در مقیاس جهانی که بین دو گروه از کشورها (متحدین و متفقین) روی داد. پس از ترور ارشیدوک فرانتس فردیناند در 28 جولای 1914 توسط یک صرب ملی گرا، امپراتوری اتریش-مجارستان به صربستان حمله کرد و
     روسیه به عنوان متحد سنتی صربستان شروع به آماده کردن نیروهای خود برای جنگ کرد. این امر باعث شد آلمان، متحد اتریش نیز برای جلوگیری از جنگ نابرابر به فرانسه، متحد روسیه حمله کند. حمله
      به فرانسه از طریق خاک بلژیک انجام شد و باعث شد که انگلستان حامی خاک این کشور نیز به آلمان اعلان جنگ کند. جنگ 4 سال به طول انجامید و غریب 10 میلیون نفر کشته برجای گذاشت. سرانجام با
       پیروزی متفقین درسال 1919م. و برگزاری کنفرانس صلح پاریس و امضای معاهده هایی بین کشورها جنگ به اتمام رسید. درخلال جنگ روسیه نیز وضعیت نابسامانی را تجربه کرد. نیروهای روس با تصور این که جنگ
        مدت زمان زیادی طول نخواهد کشید بدون آمادگی وارد جبهه های جنگ شده بودند، در حالی که نیمی از آنها حتی سلاح نداشتند. شرایط جبهه ها از نظر دانش نظامی، غذا،سلاح و مهمات اصلا مناسب نبود.همین
         موضوع باعث شد که در اولین نبرد آنها با آلمان ها که در تاننبرگ (شهری در لهستان) رخ داد 170 هزار سرباز روس کشته، زخمی و یا اسیر شدند. در طی این چهارسال به دلیل رسیدگی به جبهه های جنگ
          مواد غذایی در داخل کشورکاهش یافت، حمل و نقل دچارمشکل شد و شرایط بسیار نابسامانی در روسیه به وجودآمد تا جایی که در سال 1917 شورش هایی برسر موادغذایی در سنت پترزبورگ  به پاشد. جنگ جهانی اول،
           چهار امپراتوری را نابود ساخت: امپراتوری آلمان تاج و تخت را از دست داد و دولت جمهوری جایگزین آن شد، امپراتوری‌های شکست خورده  ی اتریش - مجارستان و امپراتوری عثمانی هر دو از هم گسیختند و
            امپراتوری روسیه نیز به دست انقلابی‌های بلشویک افتاد.</span>`,
    imgSrc: ["9/1.jpg", "9/2.jpg", "9/3.jpg", "9/4.jpg", "9/5.jpg"],
    videoSrc: "9/1.mp4",
  },
  {
    id: 10,
    ruName: "Гражда́нская война́",
    faName: "جنگ داخلی",
    enDate: "1922",
    faDate: "1296",
    description: `<span>جنگ داخلی یا جنگ شهروندی اصطلاحی است که به شورش <span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span> علیه <span class="link-to-content" onclick="goToPage(44)">دولت موقت</span> اطلاق می شود
    . در تاریخ 7 نوامبر 1917 (16 آبان 1296) نیروهای ارتش سرخ <span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span> علیه <span class="link-to-content" onclick="goToPage(44)">دولت موقت</span> که درکاخ زمستانی
     در سنت پترزبوگ مستقر شده بود، شورش کردند و به آنها حمله ور شدند. نخست وزیر وقت <span class="link-to-content" onclick="goToPage(44)">دولت موقت</span> الکساندرکِرِنسکی از شهرگریخت و <span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span> 
     توانستندکاخ را تسخیر کنند. بروز این جنگ در نهایت به وقوع <span class="link-to-content" onclick="goToPage(75)">انقلاب اکتبر</span> و سرنگونی <span class="link-to-content" onclick="goToPage(44)">دولت موقت</span> انجامید. سپس مجلسی
      تشکیل شدکه نمایندگان احزاب مختلف از جمله <span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span>، <span class="link-to-content" onclick="goToPage(34)">منشویک ها</span> و انقلابیون سوسیالیست در آن شرکت داشتند و نهایتا پس
       از مجادلات طولانی بیانه ای مبنی بر عزل دولت و دستگیری وزرای آن صادر شد  و <span class="link-to-content" onclick="goToPage(54)">لنین</span>به عنوان رهبر رژیم جدید رسما قدرت را در دست گرفت.</span>`,
    imgSrc: ["10/1.jpg", "10/2.jpg", "10/3.jpg"],
    videoSrc: "10/1.mp4",
  },
  {
    id: 11,
    ruName: "Михаи́л Фёдорович Рома́нов",
    faName: "میخاییل فئودورویچ رمانُف",
    enDate: "1596-1645",
    faDate: "975-1024",
    description: `<span>پادشاه روسی ملقب به میخاییل اول و نخستین فرمانروای خاندان رمانُف بود که تا سال 1917م. (1296 ش.)  بر روسیه حکومت کردند. در <span class="link-to-content" onclick="goToPage(18)">زمان دشواری</span> 
    و پس از درگذشت <span class="link-to-content" onclick="goToPage(62)">باریس گادونُف</span> براثرمسمومیت دوباره مشکلات برسر جانشینی آغاز شد و عدم توافق اشراف
     و درباریان باعث شد که دولت سوئد و لهستان به فکرحمله به روسیه بیفتند و نهایتا لهستان موفق شد مسکو پایتخت روسیه را تسخیرکند. آنها به دنبال زمینه سازی برای انتصاب فرزند پادشاه لهستان به عنوان
      تزارمسکو بودند. دراین شرایط بود که روس ها برای جلوگیری از رهبری یک پادشاه کاتولیک برخود، گردهم آمدند و میخاییل رمانُف نوه ی 16 ساله ی خواهر ایوان مخوف را به پادشاهی برگزیدند. وی
       با توجه به شرایط سنی نقشی در انتخاب خود  به عنوان تزار نداشت و حتی از این انتخاب بی خبر بود. شروع حکومت برای میخاییل اول با مشکلات متعددی همراه بود؛ خزانه خالی، حملات قزاق ها،
        سرزمین های غارت شده، از دست دادن نُوگاراد که توسط سوئدی ها تسخیر شده بود، از جمله مشکلاتی بودند که باید فورا برطرف می شدند. دولت روسیه تصمیم گرفت که از کشورهای ثروتمند آن زمان هلند،
         انگلستان و ایران وام بگیرد تا بتواند هرچه سریع تر این مشکلات را حل کند. و نهایتا زمانی که پدر او اسارت لهستان آزاد شد و به کشور بازگشت، ضمام امور را در دست گرفت
         . در زمان او آتش بس مابین کشورهای مشترک المنافع اعلام شد، صلح با سوئد صورت گرفت و قرارداد عدم تجاوز از سوی کریمه امضا شد. میخاییل یکم 49 سال زندگی کرد حاصل آن 10 فرزند
          بود که باعث تثبیت قدرت سلسله ی نوپای رمانُف  بودند.</span>`,
    imgSrc: ["11/1.jpg", "11/2.jpg", "11/3.jpg", "11/4.jfif"],
    videoSrc: "11/1.mp4",
  },
  {
    id: 12,
    ruName: "Рю́рик",
    faName: "روریک",
    enDate: "830-879",
    faDate: "208-257",
    description: `<span>روریک اولین پادشاه روس باستان و موسس سلسله ی <span class="link-to-content" onclick="goToPage(12)">روریکویچ</span> است. رهبران قبایل اسلاو شرقی درزمان گذشته 
    <span class="link-to-content" onclick="goToPage(25)">کنیاز</span> نامیده می شدند. بر اساس افسانه ها معروف ترین کنیاز شهر نُوگاراد گاستامیسل بود. دختر او اومیلا با کنیاز اسکاندیناویایی ازدواج کرد و
     پسری به نام <span class="link-to-content" onclick="goToPage(12)">روریک</span> را به دنیا آورد. در سال 859 م. (239ش.) ، نوگارادیان ، به دنبال اجتناب از درگیری های داخلی، نزد روریک رفتند و از
      او درخواست کردند که فرمانروایی شهر را برعهده بگیرد او از سال 862 م. (241ش.) در آنجا مستقر کرد.در سال 862 م. روریک پادشاه نُوگاراد شد. این واقعه آغاز حکومت روس باستانُ و سلسله ی <span class="link-to
      -content" onclick="goToPage(12)">روریکویچ</span> در روسیه شد. دو سال بعد، همکاران روریک اسکولد و دیر کیف را اشغال کردند و آن را از خراج خزرها آزاد کردند. در سال 879م. (257ش.)، روریک درگذشت و
       قدرت را به خویشاوند خود آلگ منتقل کرد و پسر کوچکش ایگور را به او سپرد.</span>`,
    imgSrc: ["12/1.jfif", "12/2.jpg", "12/3.jpg", "12/4.jpg"],
    videoSrc: "12/1.mp4",
  },
  {
    id: 13,
    ruName: "Креще́ние Ру́си",
    faName: "مسیحی شدن روس باستان",
    enDate: "988",
    faDate: "388",
    description: `<span>واقعه ی انتخاب مسیحیت به عنوان دین رسمی روسیه. هنگامی که شاهزاده ولادیمیر مقدس به سلطنت رسید دستور داد که معبدی از مهمترین خدایان بت پرستی را درکی یف بسازند. اما پس از مدتی احساس
     کرد که کشور نیاز به یک خدای واحد دارد که قبایل مختلف را تبدیل به یک ملت متحد کند.کشورهای اطراف روسیه خدایان متفاوتی را می پرستیدند. مسلمانان الله را، یهودیان یهوه و مسیحیان خدای مسیحی را.
      با این که هرکدام خداوند یکتا را می پرستیدند اما هر یک قوانین و آداب خاص خود را داشتند، به همین دلیل انتخاب آسان نبود. شاهزاده ولادیمیر در سال 986م. (388ش.) سفیرانی را از
       بلغارستان، رم، ایران و یونان دعوت کرد که نمایندگان ادیان  اسلام، مسیحیت-غربی، یهودیت و مسیحیت-شرقی بودند. یک سال پس از آن نمایندگان خود را برای بررسی ادیان به کشورهای مختلف فرستاد و
        از میان آنها شیفته  ی روایت نمایندگان بیزانس از مسیحیت شرقی شد و مسیحیت ارتدکس را به عنوان دین رسمی سرزمین-های تحت حاکمیت خود انتخاب کرد. بدین ترتیب روس ها پرستش
         خدایان طبیعی را کنارگذاشته و به مسیحیت گرویدند.</span>`,
    imgSrc: ["13/1.jpg", "13/2.jpg", "13/3.jpg", "13/4.jpeg"],
    videoSrc: "13/1.mp4",
  },
  {
    id: 14,
    ruName: "Григо́рий Ефи́мович Распу́тин",
    faName: "گریگوری یفیماویچ راسپوتین",
    enDate: "1869-1916",
    faDate: "1247-1294",
    description: `<span>گریگوری یفیماویچ راسپوتین در 21 ژانویه 1869 (2بهمن 1247) در روستای پاکرُفسکایه یکی از روستاهای سیبری به دنیا آمد. براساس برخی از منابع از آنجایی که در روستا مدرسه نبود وی درکودکی خواندن و نوشتن نمی دانست. طبق گفته های هم روستاییانش او چشم بصیرت داشت، مثلا اگرکسی در روستا دزدی می کرد، می توانست بگوید دزد چه کسی است و آن شیء را کجا
     قرار داده. با این وجود وی وجهه خوبی در در روستا نداشت. او در 19 سالگی ازدواج کرد و 3 فرزند داشت. در سن 28 سالگی شروع به جهانگردی مذهبی صومعه های روسیه،اوکراین، اورشلیم کرد و در سال
      1904م. (1282ش.) برای اولین بار وارد سنت پترزبورگ شد. کم کم مردم شهر فهمیدند که شفادهنده ی سیبریایی به شهر آمده، این شهرت تاجایی پیش رفت
       که او را برای درمان فرزند <span class="link-to-content" onclick="goToPage(48)">نیکلای دوم</span> به دربار دعوت کردند، او توانست بیماری هموفیلی شاهزاده را درمان کند. این عمل وی باعث نفوذش در دربار شد، و به هنگام
        جنگ جهانی اول که شاه در جبهه ها بود و همسرش الکساندرا در پایتخت، راسپوتین کاملا در اداره امور مملکت دخالت می  کرد،به تمام دوستان نزدیکش سمت های دولتی داد و تاجایی که می توانست با
         حرف هایش الکساندرا همسر پادشاه را تحت تاثیر قرار می داد تاجایی که در طول یکسال 4 نخست وزیر در روسیه عوض شد. هرج و مرجی که او در روسیه ایجاد کرد بود باعث خشم همه ی درباریان شده
          بود، به همین دلیل یوسُپُف که خود از خاندان سلطنتی بود تصمیم به قتل او گرفت. اورا به مهمانی دعوت کردند و در غذایش سم ریختند. اما  به گفته حاضرین در جمع سم بر روی او اثر نکرد و یوسپف اورا
           با گلوله زد. او نه تنها  در آن لحظه نمی میرد بلکه تا خیابان را راه می رود. نهایتا اورا به رودخانه نوا می اندازند. با اینکه تعداد افراد حاضر در این قتل زیاد بوده است اما متاسفانه اطلاعات دقیقی از نحوه ی
            مرگ او در دست نیست، برخی می گویند پزشکی که سم را به آنها داده بود نهایتا به خاطر قسم بقراط منصرف می شود وپادزهر در غذا می ریزد. در کل زندگی و مرگ راسپوتین پر است از راز و
             رمز های فراوان که هنوز هیچ منبعی به طور دقیق نمی تواند از آن صحبت کند.</span>`,
    imgSrc: ["14/1.jpg", "14/2.jpg", "14/3.jpg", "14/4.jpg"],
    videoSrc: "14/1.mp4",
  },
  {
    id: 15,
    ruName: "Манголо-тата́рское И́го",
    faName: "یوغ مغول- تاتار",
    enDate: "1206",
    faDate: "585",
    description: `<span>این اصطلاح برای اشاره به حمله مغول ها به روسیه استفاده می شود. مغولان به سرکردگی چنگیزخان در سال 1206م. (585ش.) حرکت خود را از سرزمین مادری خود در آسیای مرکزی به بیرون در همه جهت آغاز کردند. یکی
     از نخستین شهرهای روسیه که به دست مغولان افتاد ریازان شهری در 800 کیلومتری شمال شرقی کی یف بود. در طول سالهای حمله و تسلط مغول شاهزاده مسکو(ایوان سوم) با قبول ظاهری هرآنچه مغولان می
      گفتند ضمام امور را در دست گرفت و با زیرکی برقدرت خود افزود و در سده ی پانزدهم، ضعف مغول و قدرت و اتحاد شاهزادگان باعث ظهور امپراتوری جدید روسیه شد.</span>`,
    imgSrc: ["15/1.jpg", "15/2.jpg", "15/3.jpg", "15/4.jpg"],
    videoSrc: "15/1.mp4",
  },
  {
    id: 16,
    ruName: "Не́вская би́тва",
    faName: "نبرد نوا",
    enDate: "1240",
    faDate: "619",
    description: `<span>نبرد الکساندر یاروسلاویچ پادشاه <span class="link-to-content" onclick="goToPage(46)">نُوگاراد</span> در برابر نیروهای سوئدی در تاریخ 15 جولای سال 1240 که با پیروزی
     روس ها همراه شد. همین مسئله باعث شد که نام او به عنوان <span class="link-to-content" onclick="goToPage(59)">الکساندر نِفسکی</span>  در تاریخ روسیه ثبت شود. این
      نبرد از این روی اهمیت داد که باعث حفظ سواحل خلیج فنلاند شده و اجازه قطع تجارت بین روسیه و غرب را نداد.</span>`,
    imgSrc: ["16/1.jpg", "16/2.jpg", "16/3.jpg", "16/4.jpg", "16/5.jpg"],
    videoSrc: "",
  },
  {
    id: 17,
    ruName: "Кулико́вская би́тва",
    faName: "نبردکولیکُف",
    enDate: "1380",
    faDate: "759",
    description: `<span>در تاریخ 8 سپتامبر سال 1380 م. (18 شهریور 759 ش.) نبردی میان ارتش اردوی زرین تحت فرماندهی مامای و روسای قبایل روسیه
     تحت فرماندهی شاهزاده دمیتری دونسکی درگرفت. این نبرد در نزدیکی رودخانه دُن شکل گرفت و در نتیجه آن استقلال مسکو بازگشت و دولت روسیه مدرن شکل گرفت، اما باعث افول قدرت و عدم تسلط کامل
      مغولان نشد.</span>`,
    imgSrc: ["17/1.jpg", "17/2.jpeg", "17/3.jpg", "17/4.jpg", "17/5.jpg"],
    videoSrc: "",
  },
  {
    id: 18,
    ruName: "Сму́тное вре́мя",
    faName: "دوران دشواری",
    enDate: "1598-1613",
    faDate: "976-981",
    description: `<span>درسال های 1603-1602م. (982-981 ش.) سرمای وحشتناک محصولات کشاورزی را از بین برد و مردم برای زنده ماندن دست به قتل و غارت می زدند. این درحالی بودکه دولت روسیه
     نیز وضعیت نابسامانی داشت. پس از مرگ فئودور پسر <span class="link-to-content" onclick="goToPage(20)">ایوان مخوف</span> آخرین پادشاه <span class="link-to-content" onclick="goToPage(12)">سلسله ی روریکویچ</span>  درسال
      1598م. (977 ش.) به دلیل نداشتن جانشین شوهر خواهر وی <span class="link-to-content" onclick="goToPage(62)">باریس گادونُف</span>  <span class="link-to-content" onclick="goToPage(29)">تزار</span>
        شده بود. با فزونی یافتن مرگ و میر ناشی از قحطی، <span class="link-to-content" onclick="goToPage(62)">گادونُف</span>  می خواست مواد غذایی موجود در انبارهای دولتی را بین نیازمندان تقسیم کند
        . اما از آن جایی که اشراف و درباریان او را به عنوان تزار به رسمیت نمی شناختند به فرمان های او عمل نمی کردند و از انجام هرگونه عملی که موجب افزایش محبوبیت وی در میان مردم می شد جلوگیری می کردند
        . این قحطی 15 سال به طول انجامید و به گفته ی مورخان تنها در مسکو بیش از یکصد هزار نفر جان خود را از دست دادند.</span>`,
    imgSrc: ["18/1.jpg", "18/2.jpg", "18/3.jpg", "18/4.jpg"],
    videoSrc: "18/1.mp4",
  },
  {
    id: 19,
    ruName: "Григо́рий Алекса́ндрович Потёмкин-Таври́ческий",
    faName: "گریگوری الکساندرویچ پاتیُمکین تاوریچسکی",
    enDate: "1739-1791",
    faDate: "1117-1169",
    description: `<span>گریگوری الکساندرویچ پاتیُمکین تاوریچسکی در سال 1739 م.  (1118ش.) در اسمُلِنسک چشم به جهان گشود. وی یکی از سیاستمداران و نظامیان محبوب دربار<span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span>بود
    . او بارها درسیاست خارجی از منافع روسیه دفاع کردو در داخل کشور نیز خدمات بسیاری انجام داد و در سمت های فراوانی فعالیت داشت: عضو شورای دربارشاهنشاهی، فرماندهی ناوگان جنگی با درجه سرهنگ دومی، فرمانده
     عالی ارتش زره پوش، فرمانده سپاه سواره نظام، معاون رئیس دانشکده نظامی، وی به سرکوب قیام پوگاچف کمک کرد و یک هنگ از سربازان قزاق وفادار برای دفاع از میهن تشکیل داد. او
      ازحامیان خدمت سربازی بود اما برای آن شرایط استخدامی و حقوق تعریف کرد. یکی از بنیانگذاران ناوگان دریای سیاه بود و آغازگر هیات دریاداری دریای سیاه بود. خدمات بی شائبه وی تا حدی پیش رفت که سرزمین کریمه
       و لقب کنیاز به وی اعطا گردید. و تا پایان عمر یکی از نزدیکترین، فداکارترین و بانفوذ  ترین همکاران امپراتیس محسوب می شد. وی در سال 1791م. (1169ش.) در حالی که از مذاکرات صلح رومانی باز می گشت
        براثر تب درگذشت.</span>`,
    imgSrc: ["19/1.jpg", "19/2.jpg", "19/3.jpg", "19/4.jpeg"],
    videoSrc: "19/1.mp4",
  },
  {
    id: 20,
    ruName: "Ива́н Гро́зный",
    faName: "ایوان مخوف ",
    enDate: "1530-1584",
    faDate: "909-963",
    description: `<span>تزار روسی  ایوان چهارم  (ایوان واسیلویچ)  در 25 آگوست سال 1530م. متولد شد. وی در 3 سالگی پس از فوت پدر به سلطنت نشست و مادرش یلناگلینسکایا نایب السلطنه ی او شد. ایوان از 17 سالگی
     خود سلطنت را به دست گرفت اما مشارکت مستقیم و مستقل وی در امور از سال 1549 م. (927ش.) آغازشد. ایوان برای مقابله با اشراف و درباریانی که در کودکی بدترین رفتارها را با او داشتند  و حفظ حکومت خود
      دست به مبارزه ی جدی علیه آنها کرد و هرگونه مخالفت از سوی آنها مساوی با مصادره زمین ها، تبعید و یا اعدام بود. درسال های سلطنت ایوان چهارم خان های کازان و استراخان متحد مسکو شدند و به افتخار این اتحاد وی
       دستور ساخت حرم واسیلی مقدس را در میدان سرخ شهر مسکو داد. در زمان زمامداری ایوان روابط تجاری مستقیم با انگلستان شکل گرفت. ایوان در بین مردمان زمان خود فردی با سواد و با ذوق ادبی فراوان بود که نقش
        مهمی در توسعه صنعت چاپ داشت. وی در میان مردم لقب مخوف –گروزنی- را گرفت اما شخصیت او در میان ترانه های فولکلور از سوی مردم عادی شخصیتی مثبت است چرا که جنگ و خشم ایوان بیشتر بر سر اشراف و درباریان بود و این باعث می
         شد مردم وی را حافظ جان و حق خویش بدانند. سوءظن فراوان وی نسبت به اطرافیان که گمان می کرد درصدد تسخیر جایگاه وی هستند تا جایی پیش رفت که پسرخود را به قتل رساند. ایوان در سال 1584 م
         . درگذشت و در شهر آرخانگِلسک به خاک سپرده شد.</span>`,
    imgSrc: ["20/1.jpg", "20/2.jpg", "20/3.jpg", "20/4.jpg", "20/5.jpg"],
    videoSrc: "20/1.mp4",
  },
  {
    id: 21,
    ruName: "Са́нкт-петербу́рг",
    faName: "سنت  پترزبورگ",
    enDate: "",
    faDate: "",
    description: `<span>این شهر در تاریخ 27 می 1703 (6 خرداد 1802) تاسیس شد. پتر شهر را به نام پترمقدس نامید، کسی که از آموزه های مذهبی او بهره میجست. سنت پترزبورگ پایتخت امپراطوری روسیه شد و تا سال 1918 م.
     به عنوان پایتخت باقی ماند. روسیه با هدف دسترسی به دریای بالتیک 200 سال بر سر این زمین با سوئد جنگید و در نهایت در زمان پتر کبیر به این مهم دست یافت. پتر برای حفاظت از شمال روسیه
      تصمیم گرفت قلعه و بندری بر روی رودخانه نوا که به دریای بالتیک می ریزد بسازد. با تاسیس این شهر فرصت ارتباط با اروپا از طریق  دریای بالتیک فراهم شد. به همین دلیل سنت پترزبورگ را «پنجره رو به اروپا»
       می نامند. همچنین این بندر روسیه را به دریای سیاه نیز متصل می کردکه برای توسعه اقتصاد کشور نقش بسیارمهمی را ایفا می کرد. پتر با تاسیس این شهر پایتخت را از مسکو به
        سنت پترزبورگ منتقل کرد. در طول تاریخ روسیه نام این شهر چندین بار دچار تغییر شد. در سال 1914م. با تغییر ریشه آلمانی، آن را پتروگراد (شهرِ پتر) نامیدند. این شهر پس
         از مرگ لنین، رهبر <span class="link-to-content" onclick="goToPage(75)">انقلاب اکتبر</span>، درسال 1924 م. <span class="link-to-content" onclick="goToPage(54)">لنین گراد</span> نامیده
          شد و در سال 1991م. به نام اولیه خود یعنی سنت پترزبورگ بازگشت.</span>`,
    imgSrc: [
      "21/1.jpg",
      "21/2.jpg",
      "21/3.jpg",
      "21/4.jpg",
      "21/5.jpg",
      "21/6.jpg",
    ],
    videoSrc: "21/1.mp4",
  },
  {
    id: 22,
    ruName: "Боя́р (боя́рин) ",
    faName: "بزرگ-مالک (عمده -مالک)، درباری",
    enDate: "",
    faDate: "",
    description: `<span>کلمه ای چند معنایی است که در برخی متون فارسی به عنوان بویار ترجمه شده است. اما این کلمه در متون روسی در دوران مختلف بیان کننده مفاهیم مختلفی است که در ادامه به آنها اشاره می شود: 
    1)	بزرگ-مالک (عمده-مالک)
     یک زمیندار بزرگ که نماینده ی قشر بالای فئودال ها در روس باستان بوده است. این افراد در مدیریت دولت، پس از حاکمان بزرگ، جایگاه دوم را به خود اختصاص می دادند و
      در دربار به امور اقتصادی و املاک حاکمان رسیدگی می کردند. آنها تابع حاکمان بزرگ بودند و خود نیز زیردستانی را برای اداره امور داشتند. 
    2)	بایار
    در دوره ی تجزیه ی فئودالی (11-15م.) با تضعیف قدرت حاکم ها، قدرت اقتصادی و نفوذ سیاسی بایارها افزایش یافت. در جمهوری فئودالی نوگاراد آنها در واقع به ایالت ها حکومت می کردند.
     در قرن چهاردهم در زمان شکل گیری دولت متمرکز روسیه، اموال و امتیازات سیاسی بایارها به طور قابل توجهی محدود شد. در قرن  15تا 17م. با شکل گیری دومای بایارها (боярская дума  ) آنها
      حق شرکت در جلسات رسمی دولتی و مشارکت در امور اصلی، اداری، قضایی و نظامی را بدست آوردند. البته در این دوران عنوان بایار نه به همه ی ملاکان بلکه به کسانی که متعلق به خاندان های مشهوری
       بودند اطلاق می شد. در اواسط قرن شانزدهم، ایوان مخوف ضربه ی ویژه ای به قدرت اشراف و بایارها وارد کرد. در قرن 17م. در زمان پترکبیر آنها به عنوان یک طبقه ی خاص با امتیازات اجتماعی و
        اقتصادی از بین رفتند اما  لقب بایار تا قرن هجدهم در میان مردم برای خطاب به ملاکان و درباریان همچنان مورد استفاده قرار می گرفت.
    3)	درباریان
    در قرن 18م. در کنار کلمات دیگری که برای اطلاق به افراد نزدیک به دربارکاربرد داشت. 
    4)	عالی جناب
    در قرن 18م. این کلمه توسط افراد طبقات پایین تر جامعه برای خطاب قراردادن طبقات بالاتر استفاده می شد.
    </span>`,
    imgSrc: ["22/1.jpg", "22/2.jpg", "22/3.jpg", "22/4.jpg"],
    videoSrc: "",
  },
  {
    id: 23,
    ruName: "Импера́тор",
    faName: "امپراتور (شاهنشاه)",
    enDate: "",
    faDate: "",
    description: `<span>این اصطلاح نخستین بار در روم باستان برای اطلاق به افراد دارای قدرت برترنظامی، قضایی، اداری استفاده می شد. در معنای معاصر برای اشاره به فردی که در راس حکومت های فرد محور قرار دارد
     مورد استفاده قرار می گیرد. در برخی منابع فارسی وجه تمایز معنایی امپراتور در مقایسه با کلمات مترادف (تزار، پادشاه، شاه)، حکومت وی برچندین ایالت یا کشور ذکر شده است که از این
      نظر می تواند با کلمه ی شاهنشاه در زبان فارسی معال در نظر گرفته شود.
    از سال 1721 با اعلام روسیه به عنوان امپراتوری توسط پترکبیر، از کلمه ی امپراتور درکنار تزار برای اشاره به فرد اول حکومت استفاده می شد.
    </span>`,
    imgSrc: [],
    videoSrc: "",
  },
  {
    id: 24,
    ruName: "Императри́ца",
    faName: "",
    enDate: "",
    faDate: "",
    description: `<span>1)	امپراتور زن ، شهبانو
    درتاریخ روسیه تزاری چهار امپراتور زن حکومت کردند که عبارتند از: <span class="link-to-content" onclick="goToPage(56)">کاترین اول</span>، آنا ایوانونا، الیزابت پتروونا، <span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span>.
    2) همسر شاه ، شهبانو
    </span>`,
    imgSrc: [],
    videoSrc: "",
  },
  {
    id: 25,
    ruName: "Кня́зь",
    faName: "حاکم، رهبر قبیله، شاهزاده",
    enDate: "",
    faDate: "",
    description: `<span>این عنوان در متون فارسی معمولا با اصطلاح شاهزاده معادل قرار داده شده است، اما مطالعه متون روسی نشان دهنده ی چندمعنایی بودن آن برای اطلاق به مفاهیم زیر است:
    1)	رهبر قبیله 
     مقامی که عنصر ضروری و جدایی ناپذیر سیستم اجتماعی روسیه باستان بود. این عنوان بارها در نوشته های تاریخی روسیه باستان برای اشاره به رهبران قبایل مختلف مانند درولیان و ویاتیچ به چشم می خورد.
      اولین افرادی که به عنوان رهبر قبیله از آنها یاد می شود ( مانند رهبران قبیله کی یف به ویژه اولگ ، ایگور ، سویتاسلاو) رهبری محدوده ی مکانی خاصی را بر عهده نداشتند. آنها فاتحان دوره گردی بودندکه
       بیشتر به دنبال یافتن سرزمین های جدید برای غارت و دستیابی به اموال بودند تا گسترش قلمرو خود. 
    2)	حاکم
     ولادیمیر مقدس اولین حاکمی است که در یک محدوده ی مکانی مشخص (کی یف) مستقر می-شود. همزمان با او یاروسلاو را می توان نخستین حاکم با ویژگی تمایل به حفظ یک ناحیه مشخص دانست. مناطق جداگانه ای که
      بخشی ازقلمرو حکومتی ولادیمیر و یاروسلاو بودند ، ارتباط کمی با یکدیگر داشتند و حاکمان پسران خود را به عنوان نائب حاکم به سرزمین های تحت حکومت خود می فرستادند. با مرگ یاروسلاو، وحدت ظاهری
       سرزمین روسیه از بین می رود و پسران وی فرمانروایان مستقل مناطق خود می شوند. در این بین حاکم کی یف که لقب حاکم اعظم را به خود اختصاص داده بود، در میان سایر حاکمان از جایگاه والاتری برخوردار بود
       . نقش های اصلی حاکمان در روسیه باستان عبارتند از: قانون گذاری، فرماندهی جنگ و دفاع از سرزمین خود (که مهمترین مسئولیت وی به شمار می رفت). برای دفاع از سرزمین های تحت حکومت،
        حاکمان در کنار خود یک فرمانده نظامی که کاملا تحت فرمان وی بود و یک نماینده مردمی که وظیفه اجرای فرامین مجلس وچ (شورای مردمی در روسیه باستان) را برعهده داشت، در نظر می گرفتند. علاوه
         بر این حاکمان وظایف  انجام امور قضایی را نیز به طور مستقیم یا از طریق نیابتی  برعهده داشتند. حاکمان در ازای انجام وظایف خود بخشی از خراج ، درآمد دادگاه ها و داد و ستد های
          تجاری را دریافت می کردند. مالکیت خصوصی بخشی از سرزمین و دریافت بخشی از غنایم جنگی نیز از دیگر امتیازات آنها به شمار می رفت. در عین حال تامین مالی مشاوران و نیروهای نظامی از وظایف آن ها بود. حاکمان در
           همه مناطق قدرت یکسانی نداشتند. در گالیچ قدرت حاکمان به شدت توسط طبقات بالایی و در پولوتسک، اسمولنسک ،نووگاراد و پسکوف توسط مجلس وچ  محدود می شد، که گاها حتی به برکناری آن-ها نیز ختم می شد. <span class="link-to-content" onclick="goToPage(15)">یوغ تاتار </span>، در میان دلایل دیگر ، به افزایش قدرت و برجسته شدن جایگاه حاکم مسکو در میان دیگر حاکمان کمک کرد. رفته رفته همه ی حاکمان به حاکم مسکو پیوسته، جایگاه خود را از دست می دهند و به درجه ی بایارها تنزل می یابند. 
    3)	شاهزاده
    عنوانی که به وارثان حاکمان و یا به افرادی که در دوره ی حکومت تزاری شایسته دریافت این عنوان شناخته می شدند، اطلاق می گردید. 
    
    </span>`,
    imgSrc: ["25/1.jpg", "25/2.jpg", "25/3.jpg", "25/4.jpg"],
    videoSrc: "",
  },
  {
    id: 26,
    ruName: "Коро́ль",
    faName: "شاه",
    enDate: "",
    faDate: "",
    description: `<span>یکی از عناوینی که به سردمدار حکومت های فرد محور اطلاق می شود. این اصطلاح معمولا برای اشاره به رهبر یک حکومت سلطنتی مورد استفاده قرار می گیرد.</span>`,
    imgSrc: ["26/1.jpg", "26/2.jpg", "26/3.jpg"],
    videoSrc: "",
  },
  {
    id: 27,
    ruName: "Короле́ва",
    faName: "ملکه",
    enDate: "",
    faDate: "",
    description: `<span></span>`,
    imgSrc: [],
    videoSrc: "",
  },
  {
    id: 28,
    ruName: "Мона́рх",
    faName: "پادشاه",
    enDate: "",
    faDate: "",
    description: `<span>عنوان کلی برای اشاره به سردمدار حکومت های فرد محور رهبر یگانه ی دولت، که با نظر خود و به تنهایی قدرت را اداره می کند نه از طریق تفویض اختیار. به جز در موارد نادر، قدرت پادشاه مادام العمر
     است و تاج و تخت به جانشین وی که معمولا فرزند یا یکی از افراد خاندان او است، منتقل می شود. در برخی از کشورها پادشاه یک عنوان صوری است که مسئولیت حکومتی خاصی برعهده ندارد. این سمت
      در کشورهای مختلف با عناوین گوناگونی مانند شاه، سلطان، امیر و یا دوک اعظم شناخته می شود.</span>`,
    imgSrc: ["28/1.png", "28/2.jpg"],
    videoSrc: "",
  },
  {
    id: 29,
    ruName: "Царь",
    faName: "تزار",
    enDate: "",
    faDate: "",
    description: `<span>اصطلاح تزار برای خطاب به پادشاه های روسیه از زمان ایوان مخوف تا زمان پترکبیر مورد استفاده بوده است. از زمان پترکبیرکه عنوان امپراتوری برای روسیه در نظرگرفته شد،کلمه ی امپراتور جایگزین تزار شد، گرچه
     در بین عامه مردم کلمه ی تزار همچنان مورد استفاده قرار می گرفت. وجه تمایز تزار از مابقی سردمداران حکومت های فرد محور (شاه، سلطان، امیر و ...) جنبه ی تا حدی مذهبی وی و
      نفی مطلق گرایی و تمایل به تنظیم دقیق قوانین و چارچوب های دولت بر اساس جهان بینی مردم است. این ایده برای درک یکی از اصول اصلی تمدن روسیه بسیار مهم است. خودکامگی همواره
       در سنت مردم روسیه مذموم بوده است. بر این اساس تزار در امور مردم جامعه و دهقانان تا زمانی که به تهدید علیه حکومت تهدید نشوند دخالت نمی کند.</span>`,
    imgSrc: ["29/1.jpg", "29/2.png", "29/3.jpg", "29/4.jpg", "29/5.jpg"],
    videoSrc: "",
  },
  {
    id: 30,
    ruName: "Царе́вна",
    faName: "تزاردخت",
    enDate: "",
    faDate: "",
    description: `<span>اصطلاحی که برای خطاب دختر تزار استفاده می شود.</span>`,
    imgSrc: ["30/1.jpg"],
    videoSrc: "",
  },
  {
    id: 31,
    ruName: "Царица",
    faName: "تزارینا",
    enDate: "",
    faDate: "",
    description: `<span>اصطلاحی که برای خطاب به تزارهای مونث و یا همسر تزار استفاده می شود.</span>`,
    imgSrc: ["31/1.jpg", "31/2.jpg"],
    videoSrc: "",
  },
  {
    id: 32,
    ruName: "Кулаки́ (крестья́нин)",
    faName: "کولاک ها",
    enDate: "",
    faDate: "",
    description: `<span>کشاورزان به نسبت مرفهی که در دوران حکومت استولپین نخست وزیر معروف زمان سلطنت تزار <span class="link-to-content" onclick="goToPage(48)">نیکلای دوم</span> صاحب
     زمین شده بودند و طبقه جدیدی را در میان دهقانان روسی تشکیل می دادند. در سال 1917 م. که رژیم تزاری سرنگون شد پانزده درصد کشاورزان روسیه کولاک بودند و بقیه صاحب زمین
      های زراعی و یا کارگر کشاورزی بودند ولی نیمی از محصولات زراعی روسیه به وسیله ی کولاک ها تامین می شد. بعد از پیروزی انقلاب <span class="link-to-content" onclick="goToPage(35)">بلشویکی</span>
       و در نخستین سال های حکومت <span class="link-to-content" onclick="goToPage(54)">لنین</span> کولاک ها تحت فشار قرار گرفتند و محصولات اضافی آن ها ضبط و  مصادره شد. به همین جهت کولاک ها از فعالیت
        خود کاستند و مقاومت آن ها در برابر بلشویک ها باعث بروز قحطی شد و درنتیجه تغییر سیاست لنین و اعلام سیاست نوین اقتصادی گردید. سیاست جدید اقتصادی که وصول مالیات یا خرید محصولات کشاورزی
         از کولاک ها را جانشین ضبط و مصادره ی محصولات اضافی آن ها نموده بودند موجب تجدید حیات کولاک ها و افزایش تولیدات کشاورزی روسیه شد، به طوری که در سال 1927م. یعنی
          در آخرین سال  اجرای سیاست نوین اقتصادی تولید محصول غله در شوروی به حداکثر خود رسید، ولی با زمزمه    ی اشتراکی کردن کشاورزی و ضبط اراضی، حتی قبل از اجرای
           نخستین برنامه  ی پنج ساله اقتصادی این تولیدات به سرعت کاهش یافتند. به واسطه ی این تغییرات کولاک ها محصولات اضافی خود را احتکار کردند و از فروش آنها به دولت خودداری نمودند
           . مقاومت کولاک ها موجب خشم استالین و شدت عمل در اجرای طرح اشتراکی کردن کشاورزی شد و این طرح که قرار بود در طول برنامه   ی پنج ساله و به تدریج عملی شود به زور و اجبار در دوسال
            اجرا شد. استالین تصمیم گرفت کولاک ها را به عنوان یک طبقه از میان بردارد و مقاومت آنها در برابر ماموران دولت موجب کشتار بی رحمانه میلیون ها نفر و یا تبعید آنها به سیبری شد.</span>`,
    imgSrc: [],
    videoSrc: "32/1.mp4",
  },
  {
    id: 33,
    ruName: "Подкула́чник (подкула́чники)",
    faName: "پادکولاچنیکی",
    enDate: "",
    faDate: "",
    description: `<span>واژه ای است برای خطاب به طرفداران <span class="link-to-content" onclick="goToPage(32)">کولاک ها</span>که در برابر اشتراکی کردن کشاورزی توسط استالین مقاومت کردند.
     این گروه شامل کشاورزان متوسط و فقیری می شد، که ترجیح می دادند اختیار مزرعه کوچک خود را در دست داشته باشند و به صورت رعیت دولت در نیایند.  این گروه از کشاورزان نیز همانند کولاک ها توسط حکومت
      مرکزی قلع و قمع شدند.</span>`,
    imgSrc: [],
    videoSrc: "33/1.mp4",
  },
  {
    id: 34,
    ruName: "Меньшевики́",
    faName: "منشویک ها",
    enDate: "1903-1931",
    faDate: "1281-1309",
    description: `<span>منشویک ها مانند <span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span> اعضای یکی از جناح های حزب سوسیال دموکرات و دو شاخه اصلی جنبش انقلابی روسیه بودند. این
     دو شاخه در سال ۱۹۰۳م. (1281ش.) به  دنبال اختلاف نظر میان ولادیمیر <span class="link-to-content" onclick="goToPage(54)">لنین</span> و یوری مارتُف که هر دو عضو حزب سوسیال دموکرات کارگری
      روسیه بودند، بوجود آمدند. این اختلاف نظرها بر اساس برداشت‌ها و قرائت‌های متفاوت از مارکسیسم در دومین کنگره حزب در لندن پدید آمد. پس از انقلاب فوریه ۱۹۱۷ م. منشویک‌ها بیشتر مناصب حکومتی
       روسیه را در اختیار گرفتند؛ اما این مناصب در جریان انقلاب اکتبر ۱۹۱۷م. به دست بلشویک ها افتاد. سپس منشویک‌ها کوشیدند به صورت یک اپوزیسیون قانونی درآیند، اما
        <span class="link-to-content" onclick="goToPage(35)">بلشویک‌ها</span> در سال ۱۹۲۲م. (1300ش.) آنها را سرکوب کردند. در سال ۱۹۳۱م. <span class="link-to-content" onclick="goToPage(53)">استالین</span> یک سلسله محاکمه‌های نمایشی
         را علیه رهبران منشویک‌ها انجام داد و به شدت آنها را سرکوب کرد و از صحنه سیاسی شوروی حذف کرد. از رهبران معروف و تاثیرگذار حزب می توان به یوری آسیپُویچ مارتُف، پاول واسیلویچ آکسلورد، گریگوری
          والنتینویچ پلخانُف، آلکساندر نیکلایویچ پاترسُف اشاره کرد. منشویسم ایدئولوژی سیاسی اعضای میانه‌رو حزب سوسیال دموکرات کارگری روسیه بود که به عنوان منشویک‌ها شناخته شده بودند. میانه
           روی آنها به گونه ای بود که  در جریان اوج انقلاب به بلشویک ها نزدیک بودند و در دوران افول آن - به لیبرال ها. آنها برخلاف بلشویک ها که پیرو مارکسیست رادیکال بودند، از ایده
            مانیفست کمونیست پیروی می  کردند. آنها معتقد بودند که برای تغییرات باید دموکراتیک عمل کرد و باید به جامعه فرصت تغییر را داد اما لنین و بلشویک ها عقیده داشتند که برای تغییرات باید انقلاب کرد. </span>`,
    imgSrc: ["34/1.jpg", "34/2.jpg"],
    videoSrc: "34/1.mp4",
  },
  {
    id: 35,
    ruName: "Большевики́",
    faName: "بلشویک ها",
    enDate: "1903-1991",
    faDate: "1281-1309",
    description: `<span>پیروان بلشویسم- جریان سیاسی رادیکال چپ- که با سازش و تحمل مخالف هستند. مبانی نظری بلشویسم بر اساس مارکسیسم-لنینیسم و اصول ایدئولوژیکی سازمانی و تاکتیکی نوشته شده توسط لنین بنا شده است
    . بلشویسم به عنوان یک جریان سیاسی در سال 1903م. (1281ش.) در یازدهمین کنگره حزب کارگر سوسیال دموکرات روسیه در نتیجه اختلاف نظر شرکت کنندگان در کنگره در حل مشکلات نظریه و تاکتیک های مبارزه سیاسی
     و همچنین عضویت در حزب به وجود آمد. رهبران گروه های تشکیل شده <span class="link-to-content" onclick="goToPage(54)">لنین</span> و مارتف 
     بودند. <span class="link-to-content" onclick="goToPage(54)">لنین</span> و همفکرانش معتقد بودند که حزب باید سازمانی از انقلابیون حرفه ای باشد که با قوانین سفت و سخت اداره شود وتابع
      دستگاه های حکومتی باشد و مخالفان آنها معتقد بودند که عضو حزب موظف است با حزب همکاری داشته باشد و تحت رهبری سازمان حزب کار کند. طرفداران 
      <span class="link-to-content" onclick="goToPage(54)">لنین</span> اکثریت آرا را بدست آوردند و بُلشویک نامیده شدند و مخالفان آنها خود را اقلیت یا 
      <span class="link-to-content" onclick="goToPage(34)">منشویک</span> خواندند. بلشویک ها توجه ویژه ای به تبلیغات داشتند و نظرات خود را از طریق مطبوعات حزبی به مردم منتقل
       می  کردند. در اکتبر 1917 م. بلشویک ها در پتروگراد <span class="link-to-content" onclick="goToPage(75)">انقلاب اکتبر</span> را رهبری کردند، به همین دلیل در
        برخی از منابع از این انقلاب به عنوان قیام بلشویک-ها یاد می شود. از بهار سال 1917م. نام این حزب تبدیل به حزب سوسیال دموکرات بلشویک ها می شود. در دوران شوروی واژه ای
         تحت عنوان " بلشویک قدیمی" وجود داشت و به افرادی اطلاق می شد که قبل از سال 1917م. به عضویت حزب درآمده بودند. کلمه ی بلشویک در آن دوران معنی ای برابر با قهرمان داشت.
          همچنین مرسوم بود که بلشویک ها را لنینیست یا لنینیست های وفادار می خواندند. در روسیه ی امروزی کلمه ی بلشویک تنها در نام یکی از احزاب رادیکال چپ یعنی حزب ملی بلشویک وجود دارد.</span>`,
    imgSrc: ["35/1.jpg", "35/2.jpg", "35/3.jpg"],
    videoSrc: "35/1.mp4",
  },
  {
    id: 36,
    ruName: "Владимир Все́володович Мономах",
    faName: "ولادیمیر وسوالداویچ ماناماخ",
    enDate: "1053-1125",
    faDate: "431-503",
    description: `<span>    ولادیمیر ماناماخ یکی از فرمانروایان سلسله ی روریکویچ در روس باستان است. ماناماخ نوه ی ولادیمیر مقدس است. مادر ولادیمیر ماناماخ، آنا شاهدخت بیزانسی، دختر پادشاه بیزانس بود.
     ولادیمیر را به نام پدر بزرگش- امپراتور بیزانس ماناماخ نامیدند. زمانی که برادرانش بر سرجانشینی بحث داشتند، ولادیمیر حاکمان را جمع کرد و سعی کرد که آنها را
      آشتی دهد. او تلاش می کرد که اتحاد را در روس باستان (روس کی یفی) حفظ نماید و به درخواست اهالی کی-یف، فرمانروای این شهر شد. وی شهرهای بسیار زیادی را در اطراف رود دنپر ساخت.
       در زمان فرمانروایی او بود که شروع به نوشتن تاریخ روسیه کردند. یکی از آثار به جای مانده از آن زمان که در حال حاضر در موزه ی کرملین مسکو نگهداری می شود، تاج برلیان و زنجیر طلایی است
        که به عنوان سمبل های قدرت پادشاهی از بیزانس برای ولادیمیر فرستادند. ولادیمیر با گیتا شاهدخت انگلیسی ازدواج کرد. او 8 پسر و 4 دختر داشت . یکی از دلایل اصلی مشهور بودن وی
         در تاریخ روسیه دانایی و حکمت وی بود، و با نوشتن کتاب هایی مانند "چگونه باید زیست و چگونه حکومت کرد" ، "اصول تربیت فرزندان"  و ... سعی داشت آن را به نسل های بعد از خود منتقل کند.</span>`,
    imgSrc: ["36/1.jpg", "36/2.jpg", "36/3.jpg"],
    videoSrc: "36/1.mp4",
  },
  {
    id: 37,
    ruName: "Исмаил Бейк",
    faName: "اسماعیل بیگ",
    enDate: "",
    faDate: "",
    description: `<span>اسماعیل بیگ اعتماد الدوله سفیر روسیه در دربار شاه تهماسب دوم بود. در زمان زمامداری شاه تهماسب دوم دولت صفوی شرایط نامساعدی داشت، حمله   ی محمود افغان به اصفهان از یک سو و
     دستور <span class="link-to-content" onclick="goToPage(60)">پترکبیر</span> برای حمله به قفقاز از سوی دیگر، باعث شد که شاه تهماسب احساس خطر کرده و اسماعیل بیگ را به عنوان سفیر خود
      به روسیه بفرستد. هدف تهماسب از درخواست این مذاکرات، ایجاد اتحاد بین دولت روسیه و ایران در برابر نیروهای شورشی افغان بود. این سفر نهایتا به امضای عهدنامه ی سنت پترزبورگ بین دولت
       روسیه و اسماعیل بیگ بود انجامید که بدون توجه به دستورات شاه تهماسب تدوین و امضا شده بود. شاه تهماسب عهدنامه را به رسمیت نشناخت و نهایتا اسماعیل بیگ پس از بازگشت به ایران دستگیر و تبعید شد. </span>`,
    imgSrc: [],
    videoSrc: "",
  },
  {
    id: 38,
    ruName: "Петербу́ргский(мирный) догово́р",
    faName: "عهدنامه ی سنت پترزبورگ",
    enDate: "1723",
    faDate: "1101",
    description: `<span>عهدنامه ای که در تاریخ 12 سپتامبر 1723 بین دولت شاه تهماسب دوم (صفوی) و دولت  <span class="link-to-content" onclick="goToPage(60)">پترکبیر</span> امضا
     شده است. براساس این عهدنامه دولت ایران تمامی مناطق قفقاز جنوبی وغرب دریای خزر یعنی داغستان، شیروان، مازندران، گیلان و استرآباد را به دولت روسیه واگذار می کرد
      و در ازای آن دولت پتر متعهد می شد از ایران در برابر شورشیان افغان حمایت کند. شاه تهماسب دوم به محض اطلاع از مفاد این معاهده آن را بی اعتبار خواند و
       اعلام کرد که <span class="link-to-content" onclick="goToPage(37)">اسماعیل بیگ</span> بدون اجازه او این قرارداد را امضا کرده است. اما با ورود
        ارتش عثمانی به خاک ایران و سخت تر شدن اوضاع برای باقی مانده نیروهای شاه تهماسب دوم مجبور به پذیرش رسمی این معاهده شد. </span>`,
    imgSrc: [],
    videoSrc: "",
  },
  {
    id: 39,
    ruName: "Ре́штский Догово́р",
    faName: "عهدنامه رشت",
    enDate: "1732",
    faDate: "1110",
    description: `<span>قراردادی بین ایران و روسیه است که در مورد فعالیت های نظامی مقابل ترکیه می باشد. در زمان پادشاهی شاه عباس سوم و نایب السلطنگی نادرشاه که با سلطنت آنا 
    برادرزاده <span class="link-to-content" onclick="goToPage(60)">پترکبیر</span> در روسیه همزمان بود، تغییرات مثبتی در روابط ایران و روسیه به وجود آمد. این روابط مثبت منجر
     به امضای عهدنامه ی رشت در تاریخ 21 ژانویه 1732 (در شهر رشت) شد. طبق این قرارداد روسیه شهرهای مازندران، گیلان و استراباد را به ایران بازگرداند و ایران متعهد شد
      که امتیاز تجاری به روسیه  بدهد.</span>`,
    imgSrc: ["39/1.jpg"],
    videoSrc: "39/1.mp4",
  },
  {
    id: 40,
    ruName: "Гянджи́нский догово́р",
    faName: "عهدنامه گنجه",
    enDate: "1735",
    faDate: "1113",
    description: `<span>این عهدنامه در تاریخ 10 مارس 1735( 19 اسفند 1113) در شهر گنجه بین ایران و روسیه امضا شده است. در آن زمان روسیه خود را برای جنگ با ترکیه آماده می کرد و علاقه مند به اتحاد
     با ایران بود. به همین دلیل دو استان دربند و باکو را که  در دوره ی <span class="link-to-content" onclick="goToPage(60)">پترکبیر</span> تصرف کرده بود،
      به ایران پس داد و در جنگ با دولت عثمانی تبدیل به متحد ایران گردید.</span>`,
    imgSrc: ["40/1.webp", "40/2.jpg", "40/3.jpg", "40/4.jpg"],
    videoSrc: "40/1.mp4",
  },
  {
    id: 41,
    ruName: "Гюлиста́нский ми́рный догово́р",
    faName: "عهدنامه گلستان",
    enDate: "1813",
    faDate: "1191",
    description: `<span>الحاق گرجستان به روسیه در سال 1801 م. (1179ش.) و گسترش نفوذ روسیه در قفقاز باعث تشدید روابط بین روسیه و ایران شد. ایران با تکیه بر حمایت انگلستان و فرانسه، که به دنبال تقویت
     نفوذ خود در شرق بودند و با تکیه بر این واقعیت که نیروهای روسیه درگیر مبارزه با فرانسه هستند،  در سال 1804م. (1182ش.) جنگی را علیه روسیه آغاز کرد. علی رغم این واقعیت که ایران از
      انگلستان و فرانسه یارانه های پولی و سلاح های زیادی دریافت کرد، روسیه پیروز جنگ 1813-1804م. بود. در 12 اکتبر 1813 در روستای گلستان (قره باغ) پیمان صلح گلستان بین روسیه
       و ایران امضا شد. این پیمان به امضای فرمانده کل نیروهای روسیه در قفقاز و گرجستان ، ژنرال راتیشف و میرزا عبدالحسن خان مشاور امور سری دربار ایران رسید.
    مفاد عهدنامه گلستان
    1.	پادشاه ایران ضمن ابراز دوستی با امپراتور روسیه تمامی ولایات قره باغ، گنجه، شکی، شیروان، خانات شکی و شیروان، قبه، دربند و ماکو، داغستان، باکو، ایالات های شرقی گرجستان
     یعنی کاختی و کارتلی و هرجا از منطقه طالش که در تصرف دولت روسیه است به این کشور واگذار کرد.
    2.	تردد کشتی های تجاری و جنگی روسیه بر روی دریای خزر به طور کامل آزاد شد و هیچ کشوری (آن سال ها عملا کشوری به جز ایران
         و روسیه در حاشیه خزر وجود نداشت!) حق داشتن کشتی جنگی به جز روسیه در این دریاچه نداشت؛
    3.	تمامی اسرای دو طرف صرف نظر از مذهبشان و یا حتی فراری بودنشان می بایست به کشور خود بازگردند.
    4.	تجار روس از پرداخت تمامی عواید گمرکی مربوط به صادرات و واردات اجناس به ایران معاف شدند (دریافت حقوق گمرکی از تجار
         خارجی از زمان شاه تهماسب صفوی برقرار شده بود و بیشتر این درآمد صرف نیروهای نظامی و ارتش ایران می شد)؛
    5.	دولت روسیه تعهد کرد از سلطنت عباس میرزا و فرزندانش در ایران حمایت کند (این ماده از معاهده گلستان زمینه ساز نفوذ وحشتناک و تمام کمال روسیه در سالهای باقی مانده سلسله قاجار در ایران شد؛
    </span>`,
    imgSrc: ["41/1.jpg", "41/2.jpg"],
    videoSrc: "41/1.mp4",
  },
  {
    id: 42,
    ruName: "Туркманча́йский ми́рный догово́р",
    faName: "عهدنامه ترکمانچای",
    enDate: "1828",
    faDate: "1206",
    description: `<span> یکی از مهم ترین عهدنامه های دولت ایران و روسیه که در تاریخ 21 فوریه 1828 (2 اسفند 1206) امضا شد و به دومین جنگ ایران روسیه (1826-1828م.) پایان داد. نمایندگان ایران
     برای امضای این عهدنامه میرزا ابوالحسن خان شیرازی و الله‌یارخان آصف‌الدوله و از سوی امپراتور روسیه ایوان پاسکویچ بودند. طبق این قرارداد سه ایالت ایروان، نخجوان و بخش های دیگری از
      تالش به تصرف روس ها درآمد و حاکمیت ایران بر دریای خزر محدودترشد و ایران به ناچار کاپیتولاسیون را پذیرفت.
    مفاد عهدنامه 
•	دولت ایران علاوه بر مناطقی که طی عهدنامه گلستان به روسیه واگذار کرده بود مناطق ایروان، نخجوان، 
اردوباد و بخشی از دشت مغان را به روسیه واگذار کرد و از قله آرارات تا نهر آستارا به عنوان منطقه مرزی مشخص شد؛
•	دولت روسیه اجازه یافت در هر نقطه ایران که صلاح بداند کنسول و تاجر بفرستند و همچنین تعرفه کالاهای وارداتی روسی به ایران تنها 5 درصد تعیین شد؛
•	آزادی عبور و مرور کشتی های تجاری روس و دادن حق انحصاری به کشتی های جنگی روسیه برای تردد در دریاچه خزر، همچنین ایران از کشتی رانی در این دریاچه محروم شد؛
•	پرداخت ده کرور (5 میلیون تومان) به صورت اقساطی از سوی ایران به روسیه به عنوان غرامت جنگی؛
•	حمایت روسیه از ولیعهدی عباس میرزا؛
•	مبادله اسرای دو طرف در عرض 4 ماه (این بند اندکی بعد از ترکمنچای دردسر ساز شد) ؛
•	اعطای حق قضاوت کنسولی یا کاپیتالاسیون؛
•	همچنین تمامی خوانین آذربایجان که بر ضد دولت ایران شورش کرده بودند عفو شدند.
</span>`,
    imgSrc: ["42/1.png", "42/2.jpg", "42/3.jpg"],
    videoSrc: "42/1.mp4",
  },
  {
    id: 43,
    ruName: "Па́вел I Петро́вич ",
    faName: "پاول اول پترُویچ",
    enDate: "1754-1801",
    faDate: "1179-1133",
    description: `<span>پاول اول تزار روسی، فرزند <span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span> است که پس از او به سطنت رسید.
     دوران سلطنت پاول اول را گذاری بین سطلنت <span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span> و <span class="link-to-content" onclick="goToPage(4)">الکساندر اول</span>
      با اصلاحات لیبرال وی می دانند. پاول در سن 42 سالگی برتخت سلطنت نشست که بالاترین سن تاجگذاری را در میان تزارهای رومانوف دارد. نگرش وی به سیاست داخلی و خارجی
       با کاترین کبیر بسیار تفاوت داشت. به عنوان مثال او در آپریل 1797 به همراه همسرش تاجگذاری کرد، که این اولین تاجگذاری مشترک در تاریخ روسیه بود. در زمان سلطنت وی
        اصلاحاتی در جامعه به وجود آمد: تضعیف موقعیت اشراف، تعیین مجازات بدنی و مالیات برای اشراف، بهبود شرایط کشاورزان در جامعه، بهبود شرایط مسیحیان کاتولیک در روسیه؛ همچنین در زمینه ی نظامی
         نیز با احداث پادگان ها و واحدهای مهندسی و نقشه کشی نظامی، تعیین و تغییر لباس های فرم، پرورش سگ-های نظامی، اختصاص جوایز خاص و ...  توانست نظم و انضباط
          ارتش را بهبود بخشد. تفاوت نگرش پاوِِل اول با <span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span> در سیاست های خارجی اش، تاثیر بسزایی در روابط ایران
           و روسیه در زمان سلطنت وی می گذارد. ارتش روسیه که در زمان <span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span>  به دستور وی درحال
            جنگ با ایران بر سر تصرف ایالات قفقاز بود، به دستور پاوِِِِل عقب نشینی کرد. او به سرعت به هر دو ژنرال (زوبُف و گودُویچ) خود دستور بازگشت از خاک ایران را داد و ارتش روسیه تمام
             مناطق اشغالی ایران و حتی منطقه گرجستان را هم تخلیه کرد. وی پنج سال با دستورات خاص و غیرقابل پیش بینی بر روسیه حکومت کرد. پاول اول تمام دستورات مادرش را لغو کرد و بعد از شکست
              ارتش روسیه از فرانسه از شیفتگان ناپلئون بناپارت شد. تصمیمات وی دولت بریتانیا را ترساند. آنها توطئه ای برای قتل تزار ترتیب دادند و با همراهی اطرافیان (از
                 جمله الکساندر، ولیعهد وی، و درباریان) او را در سن 47 سالگی خفه کردند و به قتل رساندند. پس از مرگ پاول، تزار الکساندر 24 ساله به تخت نشست.</span>`,
    imgSrc: ["43/1.jpg", "43/2.jpg", "43/3.jpg", "43/4.jpg"],
    videoSrc: "43/1.mp4",
  },
  {
    id: 44,
    ruName: "Временно́е прави́тельство",
    faName: "دولت موقت",
    enDate: "1917",
    faDate: "1295-1296",
    description: `<span>بالاترین دستگاه قانونگذاری و اجرایی قدرت دولتی در روسیه بین انقلاب های فوریه و <span class="link-to-content" onclick="goToPage(75)">اکتبر</span>. دولت موقت
     در روزهای انقلاب فوریه در جریان گفتگوی بین دومای دولتی پیشین و کمیته ی اجرایی پتروگراد به وجود آمد. در این جلسه کمیته اجرایی، دولت را در اختیار اعضای قدیمی دومای روسیه گذاشت و این درحالی
      بود که در حقیقت قدرت در دست کمیته ی اجرایی بود چرا که راه آهن، مترو،سربازان، کارگران و ... همه زیر نظر آن فعالیت می کردند. آنها با ارائه انبوهی از اصلاحات، قوانینی را برای
       درنظرگرفتن حقوق مدنی و شهروندی برای تمام افراد جامعه وضع کردند. اما این تصمیمات برای حل مشکلاتی که روسیه در آن روزها با آن دست به گریبان بود راه حلی نداشت. دولت موقت تمامی تبعیدیان سیاسی را آزاد کرد
        و این را می-توان یکی از بزرگترین اشتباهات آنها به شمار آورد، چرا که آنها به دنبال سرنگونی دولت موقت بودند. در آن زمان روسیه کماکان در جبهه های جنگ به نفع متحدانش می جنگید و کرنسکی وزارت
         جنگ را برعهده داشت. اما عملکرد وی نیز چندان بهتر از <span class="link-to-content" onclick="goToPage(29)">تزار</span> نبود و نهایتا به
          عقب نشینی یکم جولای ختم شد. نهایتا کار دولت موقت با <span class="link-to-content" onclick="goToPage(75)">انقلاب اکتبر</span> و حمله ی نیروهای تروتسکی فرمانده ارتش سرخ به نیروهای کرنسکی
           در کاخ زمستانی به پایان رسید.</span>`,
    imgSrc: ["44/1.jpg", "44/2.jpg", "44/3.jpg", "44/4.jpg"],
    videoSrc: "44/1.mp4",
  },
  {
    id: 45,
    ruName: "Ки́ев",
    faName: "کی یف",
    enDate: "",
    faDate: "",
    description: `<span>در زمان های قدیم مرکز اسلاو شرقی و پایتخت قدرتمند روس کیفی بود. صحبت در مورد کی-یف در آسیا و اروپا بر سر زبان ها بود. در آثار مکتوب تاریخی «داستانی از زمان های
     کهن» (  по́весть временны́х лет ) و «کلامی در باب هنگ ایگور»  сло́во о полку́ И́гореве در مورد این شهر سخن به میان آمده است. بر اساس افسانه ها در پایان قرن پنجم میلادی 3 برادر به نام های  کی، شِک و خاریف و خواهرشان
      لیبد  در ساحل رود دنِپر شهری را بنا کردند و به احترام برادر بزرگترشان شهر را کی  یف نامیدند. قبایل اسلاو شرقی "درولیان، پالیان، اولیچی، لیپوریان"  در اطراف کی  یف گرد هم آمدند. در سال
       882م. (261ش.)  فرمانروای نُوگاراد آلِگ، کی یف را تسخیر کرد و آن را پایتخت روس باستان (مهد سه گروه نژاد اسلاو- روس ها، اوکراینی ها و بلاروس ها) نامید. به مرور کی یف مرکز بزرگ سیاسی
        قبایل اسلاو شرقی شد. این قبایل به زبان روسی باستان (اسلاو شرقی که بعدها سه زبان امروزی روسی، اوکراینی و بلاروسی از آن به وجود آمدند) سخن می گفتند
        . در سال 988 م. با <span class="link-to-content" onclick="goToPage(13)">مسیحی شدن روس باستان</span> (انتخاب مسیحیت به عنوان دین رسمی با تصمیم ولادیمیر مقدس) روابط سیاسی، فرهنگی با امپراتوری بیزانس، بلغارستان و دیگر دولت های اروپایی و
         خاورمیانه بهبود یافت. در قرن 11م. کی  یف یکی از مشهورترین مراکز تمدن در دنیای مسیحیت شد، شهر بیش از 150 هزار نفر جمعیت داشت و حدود 400 کلیسا درآن بنا شده بود. یک پارچگی دولت
          کی یف پس از مرگ <span class="link-to-content" onclick="goToPage(36)">ولادیمیر ماناماخ</span> در سال 1125 م. از بین رفت و در این سرزمین چند خان نشین به وجود آمد که به تنهایی نمی توانستند در برابر تهاجم بیگانگان مقاومت کنند
          . خان باتی، از خان های تاتار، از این موقعیت استفاده کرد . در سال 1240م. (619ش.) کی   یف  نابود و خالی از سکنه شد. این مساله حدود 100 سال کی یف را از پیشرفت باز داشت. به همین
           دلیل در مورد این شهر در انتهای سده 13 م. اطلاعات خیلی کمی برجای مانده است.  این شهر در سال 1362 م. (740 ش.)  به تصرف ارتش بزرگ لیتوانی در آمد.  در انتهای قرن 16 میلادی – قیام مردم اوکراین علیه
            اشغالگران آغاز شد. در سال 1648م. ارتش کی  یف به رهبری باگدان خمِلنیتسکی وارد عمل شد و به زودی بخش بزرگی از اوکراین و کی  یف آزاد شد. باگدان مجبور بود که همزمان در چند جبهه بجنگد. با
             لیتوانی و لهستان، با خان کریمه و سلطان ترکیه. او برای کمک نظامی پیش شاه مسکو رفت و در سال  1654 م. در شهر پریاسلاو اتحاد مسکو و اوکراین امضا شد و کی یف تبدیل به بخشی از امپراتوری روسیه شد.</span>`,
    imgSrc: ["45/1.jpg", "45/2.png", "45/3.jpg"],
    videoSrc: "45/1.mp4",
  },
  {
    id: 46,
    ruName: "Вели́кий Но́вгород",
    faName: "ولیکی نووگاراد",
    enDate: "",
    faDate: "",
    description: `<span> نُوگاراد از قدیم الایام راه ارتباطی میان روسیه و کشورهای اروپای غربی بود. طبق وقایع نگاری ها در سال 862 م. (241ش.) اسلاوها از <span class="link-to-content" onclick="goToPage(12)">روریک</span>
     دعوت کردند که فرمانروایی آنها را برعهده بگیرد و این مساوی بود با آغاز سلسله ی <span class="link-to-content" onclick="goToPage(12)">روریکویچ</span> بر
      سرزمین روسیه (از قرن 9 تا قرن 16م.). در قرن دهم میلادی نُوگاراد تبدیل به یک شهر بزرگ تجاری و صنعتی زمان خود شد و در قرن 12م. موفق به تشکیل  جمهوری بایاری (бояр→) شدند
      .  پس از تشکیل این جمهوری قدرت به دست وِچه  (مجلس شورای نووگاراد) افتاد. جمهوری نُوگاراد حدود 300 سال فعالیت کرد. این شهر مهد نخستین تاریخ نگاری های روسی
       بوده است که از قرن 11 تا 14م. به جای مانده است. در قرن 15 میلادی ایوان سوم نووگاراد را به قلمرو مسکو اضافه کرد و این شهر را تبدیل به استان کرد.</span>`,
    imgSrc: ["46/1.gif", "46/2.jfif", "46/3.jpg", "46/4.jfif"],
    videoSrc: "46/1.mp4",
  },
  {
    id: 47,
    ruName: "Москва",
    faName: "مسکو",
    enDate: "",
    faDate: "",
    description: `<span>مسکو یکی از باستانی ترین (قدیمی) ترین شهرهای روسیه است. نام مسکو برای نخستین بار در نوشته های سال 1147م. (525ش.) به چشم می خورد. تاریخ دانان "یوری دالگاروکی" را پایه گذار مسکو
     می دانند. وی درسال 1156 م. (534ش.) با ساخت یک قلعه چوبی شهر را بنا کرد. وجود باتلاق ها و جنگل های غیرقابل نفوذ واقع در سواحل رودخانه مسکو، قرارگیری در مسیر تجاری و بازرگانی دلایل اصلی برای
      انتخاب این منطقه به شمار می روند. از میانه  ی سده  ی دوازدهم تا شروع قرن سیزدهم دوره ی بسیار تاثیرگذاری برای پیشرفت
       <span class="link-to-content" onclick="goToPage(47)">مسکو</span> و تبدیل آن به یک شهر قرون وسطایی بود. <span class="link-to-content" onclick="goToPage(47)">مسکو</span> بزرگترین مرکز فرهنگی
        روسیه شد؛ جایی که علم، معماری و هنر در آن توسعه یافت. در عرض دو قرن <span class="link-to-content" onclick="goToPage(47)">مسکو</span> از یک خان نشین تبدیل به پایتخت دولت متحد روسیه شد. در
         سال 1721م. (1099ش.)  با وجوداینکه پایتخت از <span class="link-to-content" onclick="goToPage(47)">مسکو</span>  به  <span class="link-to-content" onclick="goToPage(21)">سنت پترزبورگ</span> منتقل شد اما
          کماکان این شهر موقعیت خود را به عنوان پایتخت دوم حفظ کرده بود و حتی تاجگذاری <span class="link-to-content" onclick="goToPage(29)">تزارها</span> در این شهر انجام می شد. از 12 مارس 1918 <span class="link-to-content" onclick="goToPage(47)">مسکو</span> دوباره
           به  پایتخت روسیه تبدیل شد و از 3 دسامبر 1922 تا 25 دسامبر 1991 پایتخت <span class="link-to-content" onclick="goToPage(52)">اتحاد جماهیر شوروی</span> بود. از واقعه ی آتش سوزی مسکو در
            زمان <span class="link-to-content" onclick="goToPage(6)">جنگ میهنی</span>  به عنوان یکی از حماسی ترین وقایع تاریخی روسیه یاد می شود.</span>`,
    imgSrc: ["47/1.jpg", "47/2.jpg", "47/3.jpg", "47/4.jpg"],
    videoSrc: "47/1.mp4",
  },
  {
    id: 48,
    ruName: "Николай II",
    faName: "نیکلای دوم",
    enDate: "1918-1986",
    faDate: "1246-1296",
    description: `<span>امپراتور نیکلای دوم آخرین <span class="link-to-content" onclick="goToPage(29)">تزار</span> خاندان <span class="link-to-content" onclick="goToPage(67)">رومانُف</span> که با او
     نظام سلطنتی در روسیه پایان یافت. وی در 14 می 1896 به پادشاهی رسید. جشن تاج گذاری او در 18 می  با فاجعه ای عظیم در مسکوهم زمان بود. در جریان جشن تاج گذاری و به هنگام تقصیم
      هدایای امپراتور، به دلیل هماهنگی نادرست مراسم و همهمه ی جمعیت باعث شد که هزاران نفر جان باختند و ده ها هزار نفر زخمی شدند. این اولین رویداد خونین در تاریخ سلطنت نیکلای دوم بود. در
       زمان سلطنت وی روسیه با وقایع ناگوار زیادی روبرو شد: شکست در <span class="link-to-content" onclick="goToPage(74)">جنگ با ژاپن</span> درسال های 1904-1905م.،
        یکشنبه خونین 9 ژانویه 1905 (1284ش.)، جنگ جهانی اول  و .... در 14 نوامبر 1894، نیکلای با الکساندرا فئودوروونا نوه ملکه ویکتوریا انگلستان ازدواج کرد. نتیجه ی این ازدواج چهار دختر
         و یک پسر بود. ولیعهد وی الکسِی نیکلایویچ مبتلا به بیماری هموفیلی بود، بیماری که بطور وراثتی در دربار انگلستان وجود داشت و او از مادر به ارث
          برده بود. بیماری وی باعث ورود <span class="link-to-content" onclick="goToPage(14)">گریگوری راسپوتین</span> به دربار <span class="link-to-content" onclick="goToPage(29)">تزار</span> شد. در سال
           1913 م. روسیه به طور رسمی 300 مین سالگرد سلطنت سلسله رومانُف را جشن گرفت، اما بدیهی بود که کشور از خودکامگی خسته است و به عمر این سلطنت رو به اتمام است. در آگوست
            1914م. (1293ش.) روسیه در اتحاد با انگلیس و فرانسه با آلمان و اتریش-مجارستان مخالفت کرد و وارد جنگ جهانی اول شد. تزار نیکلای دوم با وضعیتی روبرو شد که
             هیچ <span class="link-to-content" onclick="goToPage(29)">تزار</span> دیگری روبرو نشده بود: تزارها در طول جنگ های ناخوشایند فرمان رانده بودند، اما نه جنگی به خونباری <span class="link-to-content" onclick="goToPage(9)">جنگ جهانی اول</span>؛
              تزارها نا محبوب بودند، اما هیچ  گاه کل نظام تزاری با چنین وسعتی با مخالفت مردم روبرو نشده بود؛ تزارها درجریان قحطی ها به حکومت خود ادامه داده بودند، اما هیچ گاه فاقد یک ارتش نیرومند برای برقراری نظم نبودند؛
               تزارها شورش های دهقانی را درهم شکسته بودند، اما هیچ گاه یک تزار با اعتراضات گسترده کارگران صنعتی روبه رو نشده بود. در نتیجه ی این وقایع مجلس دوما دریافت که نیکلای دوم دیگر نمی تواند برکشور حکومت کند. بدین ترتیب یک <span class="link-to-content" onclick="goToPage(44)">دولت موقت</span>
                تشکیل شد که تا زمان برگزاری انتخابات حکومت کند. هیئتی از نمایندگان با تزار ملاقات کرد. او که انتخاب دیگری نداشت، کناره گیری کرد و عصر 450 ساله حکومت تزاری روسیه به سر آمد. پس از کناره گیری
                 نیکلای دوم، برخی از روس ها اصرار داشتند که برادر او میخائیل به عنوان تزار جای او را بگیرد، اما میخائیل دریافت که بدون حمایت عمومی او فاقد هرگونه قدرت واقعی خواهد بود و به در
                  سال 1918 م. تیرباران شدند. درباره ی روزهای پایانی زندگی آخرین تزار روسیه و همین دلیل حاضر به قبول مسئولیت نشد. در نتیجه این تحولات و سپس <span class="link-to-content" onclick="goToPage(75)">انقلاب اکتبر</span>، 
                  تزار و خانواده اش  ابتدا به شهر تابولسک تبعید شده و پس از آن در شهر یکاترین بورگ زندانی بوده و در همان شهر توسط ارتش سرخ خانواده ی وی (به ویژه کوچکترین دختر وی آناستازیا) روایات گوناگونی
                   در منابع مختلف وجود دارد.</span>`,
    imgSrc: ["48/1.jpg", "48/2.jpg", "48/3.jfif", "48/4.jpg"],
    videoSrc: "48/1.mp4",
  },
  {
    id: 49,
    ruName: "Перестро́йка",
    faName: "تجدید ساختار",
    enDate: "1985-1991",
    faDate: "1364-1370",
    description: `<span>پِرِسترُیکا (تجدید ساختار) یک تغییر بزرگ در تاریخ <span class="link-to-content" onclick="goToPage(52)">شوروی</span> محسوب می شود که از سال 1985- 1991 (1370-1364ش.) رخ داده است. این رویداد در زمان ریاست
     جمهوری <span class="link-to-content" onclick="goToPage(51)">میخاییل گُرباچُف</span> رخ داده است. برخی این رویداد را عامل اصلی فروپاشی شوروی می دانند. از نظرگرباچُف برای رهایی از رکودی که در زمان بِرژِنِف به وجود آمده است،
      دولت نیاز به بازسازی و اصلاح ساختارهای سیاسی، اجتماعی و اقتصادی داشت. هدف پِرِسترُیکا اصلاح و توسعه ی شرایط اقتصادی و اجتماعی کشور، تجدید قوا در نظام سیاسی، افزایش روابط خارجی و شکوفایی صنعت بود. برنامه  ی پرسترُیکا
       در سه مرحله پیشنهاد شد: مرحله  ی اول شامل شتاب-بخشیدن به توسعه ی اجتماعی و اقتصادی (اوسکارنیه)، مبارزه با اعتیاد به الکل، تبدیل الگوی شتاب بخشی به پرسترُیکا (تغییر ساختار)؛ شبیه سازی
        مفاهیم سرمایه داری (کاپیتالیستی) سود و مراکز سود دهی در نظام اقتصادی شوروی(خازراسچُت)، اعلام عمومی گلاسناست، استقلال و خصوصی سازی شرکت ها؛ مرحله ی دوم شامل توسعه ی کارآفرینی خصوصی و اصلاحات سیاسی؛
         و مرحله ی سوم تعیین پست ریاست جمهوری و اصلاح بازار اقتصادی. پس از اجرای مرحله ی دوم کشور به دو گروه کمونیست و دموکرات تقسیم شد که باعث ایجاد تنش در محیط اجتماعی و کنترل ناپذیری فرایند
          پرسترُیکا شد. در این دوران بسیاری از زندانیان زمان <span class="link-to-content" onclick="goToPage(53)">استالین</span> آزاد شدند. آثار ادبی که توقف چاپ شده از جمله
           آثار سُلژنیتسین دوباره چاپ شدند. برنامه های انتقادی مانند " دیدگاه- وِزگلیات" در تلویزیون شروع به پخش شد و روزنامه های منتقدی همچون "استدلال ها و حقایق" چاپ شدند. بسیاری از فیلم
            هایی که قبلا پخش آنها ممنوع شده بود مانند "قلب سگی" دوباره به روی پرده رفتند و حکومت درهای انتقاد از خود را گشود. گُرباچُف برای از بین بردن تحریم ها و بهبود روابط
             خارجی با غرب امتیازات زیادی را به آمریکا داد. وی در ملاقاتی که در سال 1989م. (1368ش.) با ریگان رئیس جمهور آمریکا داشت قرارداد کاهش تسلیحات نظامی و خروج نیروهای نظامی روسیه از افغانستان را
              امضا کرد. مرحله ی دوم که نتیجه ی آن تبدیل کشور به یه کشور سوسیال دموکرات بود، محقق نشد. تناقضات در مرحله ی دوم باعث شد که جمهوری ها اعلام استقلال کنند.  در سال 1990 (1369م.) لیتوانی
               خروج خود را از اتحاد جماهیر شوروی اعلام کرد و در همان سال سمت رئیس جمهور برای اتحاد جماهیر شوروی با رای مستقیم مردم انتخاب شد. اما این اصلاحات هیچ کدام
                نتایج مثبتی دربرنداشت. پیامدهای مرحله ی سوم فرایند "پرسترُیکا" کودتای سال 1991 (1370ش.) بود که به دلایل سیاسی، اجتماعی و اقتصادی منجر به فروپاشی اتحاد جماهیر شوروی شد.</span>`,
    imgSrc: ["49/1.jpg", "49/2.jpg", "49/3.jfif", "49/4.jpg"],
    videoSrc: "49/1.mp4",
  },
  {
    id: 50,
    ruName: "Гла́сность",
    faName: "گلاسناست",
    enDate: "",
    faDate: "",
    description: `<span>گِلاسناست (آزادی بیان) اصطلاحی است که در زمان <span class="link-to-content" onclick="goToPage(51)">میخائیل گُرباچُف</span> در شوروی مطرح شد. در دوران <span class="link-to-content" onclick="goToPage(49)">پرسترُیکا</span> 
    دولت به مردم و مطبوعات اجازه ی انتقاد و آزادی بیان و ایده داد. آنها اجازه داشتند که از سیاست های <span class="link-to-content" onclick="goToPage(53)">استالین</span> انتقاد کنند و سوء استفاده های رژیم
     کمونیستی از مردم که در دوره های قبل انجام  شده بود را به گوش آنها برسانند. اما این انتقادها و افشاگری ها نمی توانست سیاست های <span class="link-to-content" onclick="goToPage(54)">لنین</span> و <span class="link-to-content" onclick="goToPage(51)">گرباچف</span>
      را زیر سوال ببرد، به همین دلیل آن را به سیاستی نیمه حقیقت تبدیل کرد، که بر اساس آن تنها انحراف ها از ایده ی مارکسیست -لنینیسم محکوم می شد. گلاسناست و فعالیت مطبوعات باعث افزایش آگاهی بسیاری
       از شهروندان شوروی شد، آثار بسیاری از نویسندگان چاپ شد، کنفرانس های سیاسی در تلوزیون پخش شد، همچنین گلاسناست باعث نفوذ و برجستگی شخصیت های عمومی لیبرال شد و چند حزب همزمان در شوروی
        شروع به فعالیت کردند. در سال 1990 م. (1369ش.) بود که برای بازه ی کوتاهی گلاسناست جای خود را به آزادی بیان داد، اما پس از مدتی رسانه ها نیز وابسته به
         ساختارهایی شدند که بودجه ی آنها را تامین می کردند.</span>`,
    imgSrc: ["50/1.jpg", "50/2.jpg", "50/3.jpg"],
    videoSrc: "",
  },
  {
    id: 51,
    ruName: "Михаи́л  Серге́евич Горбачёв",
    faName: "میخائیل سرگییویچ گُرباچُف",
    enDate: "",
    faDate: "",
    description: `<span>میخائیل سرگییویچ گُرباچُف متولد 2 مارس 1931 اولین و آخرین رئیس جمهور اتحاد جماهیر شوروی است. وی در دوران فعالیت سیاسی خود فعالیت های زیادی انجام داده است که هم می توان او را فردی موفق
     و هم ناموفق خواند. <span class="link-to-content" onclick="goToPage(49)">پرسترویکا</span> یکی از وقایع دوران ریاست جمهوری وی است. او در خانواده ی دهقان به دنیا آمد و در <span class="link-to-content" onclick="goToPage(6)">جنگ جهانی دوم</span>
      پدر خود را از دست داد، در دوران تحصیلش هم در مزرعه کار می کرد و هم درس می خواند. میخائیل گرباچف در سن 19 سالگی با دریافت مدال نقره ی تحصیلی بدون کنکور وارد
       دانشگاه دولتی مسکو شد و در سال 1952 به عضویت حزب کمونیست شوروی درآمد. او پس از فارغ التحصیلی از دانشگاه در رشته ی حقوق فعالیت خود را با دادستانی استاوروپل ایالت زادگاهش شروع
        کرد و پس از آن به سمت دبیر اول حزب منصوب شد. در سال 1978 م. به عنوان دبیر کمیته ی مرکزی حزب کمونیست شوری منصوب شد و در 1980م. عضو دفتر
         سیاسی حزب شد. در سال 1985م. پست دبیرکل کمیته ی مرکزی را دریافت کرد و نهایتا در 1990 با حفظ سمت به عنوان رئیس
          جمهور <span class="link-to-content" onclick="goToPage(52)">اتحاد جماهیر شوروی</span> انتخاب شد. از وقایع مهم دوران وی می توان به
           این موارد اشاره کرد: پرسترُیکا و گلاسناست، انتخاب یلتسین به عنوان دبیر اول حزب در <span class="link-to-content" onclick="goToPage(47)">مسکو</span>، معرفی
            تعاونی ها و برداشته شدن محدودیت های مبادلات ارزی، دستور اجرای تظاهرات روز کارگر 1 می1986  در مینسک و <span class="link-to-content" onclick="goToPage(45)">کی یف</span>
             علی رغم آلودگی هسته ای ناشی از فاجعه ی چرنوبیل، مخالفت علنی با استقلال لیتوانی، لتونی و استونی و تلاش برای کاهش تشنج ها و سرکوب تظاهرات در گرجستان، گردهمایی جوانان
              در قزاقستان و درگیری های به وجود آمده در منطقه ی قره باغ. سال های زندگی و ریاست اولین رئیس جمهور اتحاد جماهیر شوروی با ناکامی های زیادی همراه بود:
               محصولات به سرعت از فروشگاه ها ناپدید می شدند، سیستم جیره بندی برای انواع مواد  غذایی معرفی شد و ابر تورم نتیجه ی این فرایند بود. بدهی های خارجی دولت
                گورباچف از 31.1 میلیارد دلار به 70.3 میلیارد دلار رسید. در حوزه ی سیاست خارجی او تلاش بسیاری برای برقراری ارتباط با کشورهای غربی داشت و
                 در تلاش برای بهبود روابط با ایالات متحده، تصمیم گرفت هزینه های نظامی را کاهش دهد. در دوران او پیمان ورشو لغو شد و نیروهای روسیه
                  از افغانستان خارج شدند. به گفته ی مورخان همه ی این اتفاق ها منجر به شکست <span class="link-to-content" onclick="goToPage(52)">اتحاد جماهیر شوروی</span>
                   و جنگ سرد شد و به فروپاشی اولیه ی آن کمک کرد.</span>`,
    imgSrc: ["51/1.jpg", "51/2.jpg", "51/3.jpg"],
    videoSrc: "51/1.mp4",
  },
  {
    id: 52,
    ruName: "СССР",
    faName: "اتحاد جماهیر شوروی سوسیالیستی",
    enDate: "",
    faDate: "",
    description: `<span>اتحاد جماهیر شوروی سوسیالیستی در سال 1922م.  (1301ش.) پس از پیروزی بلشویک ها بر دولت موقت به وجود آمد.  این اتحاد از این نظر حائز اهمیت
     است که به واسطه ی آن کشوری با وسعت 22 میلیون کیلومتر مربع در نقشه ی جهان ظاهر شد که 1.6 کره ی زمین را در بر می گرفت. جمهوری های بلاروس، اوکراین، روسیه و
      قفقاز جنوبی در 29 دسامبر 1922 پیمان ایجاد اتحاد جماهیر شوروی را امضا کردند. از دلایل ایجاد دولت جدید می توان به جلوگیری از دخالت کشورهای دیگر، انزوای اقتصادی از
       سوی کشورهای دیگر و نیاز به متمرکز سازی توان دفاعی کشور را نام برد. اولین کشورهایی که اتحاد جماهیر شوروی را به رسمیت شناختند عبارت بودند از: افغانستان، آلمان، ایران،
        مغولستان، لهستان، فنلاند و ترکیه. در سال 1924م. (1303ش.) قانون اساسی اتحاد جماهیر شوری سوسیالیستی به تصویب رسید. ایجاد این اتحاد باعث بازیابی سریع اقتصاد نابود شده در
         جنگ داخلی شد. بدین ترتیب، شوروی به یکی از ابرقدرتها در عرصه بین المللی شد، که در بسیاری از زمینه های نظامی و صنعتی با کشورهای غربی در رقابت
          بود، عنوان دومین کشور سازنده ی سلاح هسته ای در دنیا را به خود اختصاص داد و موفق به ارسال نخستین ماهواره و پس از آن نخستین انسان به فضا
           شد. این کشور در ۲۵ دسامبر ۱۹۹۱(1370ش.)  با استعفای <span class="link-to-content" onclick="goToPage(51)">میخائیل گرباچف</span>
            و اعلام استقلال دوازده جمهوری تشکیل دهنده منحل شد و حقوق و تعهدات آن به جمهوری فدراتیو روسیه به ارث رسید.</span>`,
    imgSrc: ["52/1.png", "52/2.jpg", "52/3.jpg", "52/4.png", "52/5.jpg"],
    videoSrc: "52/1.mp4",
  },
  {
    id: 53,
    ruName: "Стали́н",
    faName: "یوسف ویساریوناویچ استالین",
    enDate: "",
    faDate: "",
    description: `<span>یوسف ویساریوناویچ استالین (جوکاشویلی) در 21 دسامبر 1879 ( 30 آذر 1258) در شهر گوری گرجستان متولد شد. گرجستان در آن زمان جزئی از خاک روسیه بود. او در سال 1894
     از مدرسه الهیات گوری فارغ التحصیل شد و در همان زمان وارد حوزه ی علمیه ی ارتدکس تفلیس شد. در آگوست 1898م. (30 مرداد 1277) به حزب سوسیال دموکرات کارگری روسیه پیوست و
      در سال 1899 م. به دلیل شرکت در محافل مارکسیستی از حوزه علمیه اخراج شد. وی در دوران زندگی خود بارها به دلیل فعالیت های سیاسی زندانی و تبعید شد، و بارها
       توانست با تغییرچهره و نام فرار کند و در مناطق مختلف به فعالیت های حزبی خود ادامه دهد. وی قبل از بازگشت لنین فعالیت های کمیته مرکزی وکمیته بلشویک های سنت پترزبورگ را هدایت می کرد
       . انتصاب استالین در سال 1922م. (1301ش.)  به عنوان منشی کمیته اجرایی حزب نقش بسزایی در شکست رقبا و انتخاب او به عنوان رهبر
        پس از <span class="link-to-content" onclick="goToPage(54)">لنین</span> داشت. در زمان رهبری وی صدها نفر در گروه " دشمنان مردم" قرار گرفتند و تیرباران
         شدند. <span class="link-to-content" onclick="goToPage(69)">تصفیه ی خونین</span> عنوانی بود که به کشته شدن بسیاری از دانشمندان، متخصصان، رهبران نظامی و شهروندان اطلاق شد. سرکوب های آن
          زمان جان میلیون ها نفر را گرفت. همزمان با این وقایع، روند مدرنیزه شدن کشور نیز در حال اجرا بود. به لطف اراده آهنین استالین، سوسیالیسم با سرعت بیشتری ساخته شد. همه ی منابع در اختیار توسعه
          -ی صنعتی کشور قرار گرفتند. برنامه ی پنج ساله ی توسعه تبدیل به قانون و لازم الاجرا شد. به واسطه  ی این که کشور فاقد متخصصان کافی برای به سرانجام رساندن این برنامه بود، از متخصصان خارجی
           جهت آموزش کارگران و مهندسان شوروی دعوت شد. در پنج ساله ی نخست در کشور حدود 1500 شرکت بزرگ احداث و ابزارها و ماشین آلات جدید مانند هواپیما، اتومبیل، ماشین آلات
            کشاورزی و صنعتی ساخته شدند.در نتیجه ی این اقدامات اتحاد جماهیر شوروی تا سال 1941 م. به پایگاه صنعتی قدرتمندی دست یافت، که بدون آن تامین
             سلاح ارتش سرخ در <span class="link-to-content" onclick="goToPage(6)">جنگ کبیر میهنی</span> غیرممکن بود. اراده و استقامت آهنین استالین در پیروزی مردم شوروی در جنگ بزرگ میهنی نقشی عظیم ایفا کرد.
              وی حتی در خطرناک ترین لحظه ، هنگامی که در پاییز 1941م. (1320ش.) آلمان ها به مسکو نزدیک شدند، مسکو را ترک نکرد. درسال های پس از جنگ، سخنرانی چرچیل ، پایه و اساس "جنگ سرد" بین غرب
               و شرق را ایجاد کرد. <span class="link-to-content" onclick="goToPage(52)">اتحاد جماهیر شوروی</span> به این به خوبی پاسخ داد و در عرض چند سال، نه تنها اقتصاد ملی نابود شده توسط جنگ را بازیابی کرد، بلکه
                پایه های موشک هسته ای را نیز پایه گذاری کرد، که در دهه آینده دستیابی به اهداف نظامی را ممکن ساخت. <span class="link-to-content" onclick="goToPage(53)">استالین</span>
                 در 5 مارس 1953 (1332ش.) درگذشت. ابتدا جسد مومیایی شده ی وی را درکنار لنین به خاک سپرده بودند و اما بلافاصله بعد از پایان کنگره بیست و دوم حزب کمونیست شوروی، یعنی روز اول نوامبر
                  1961 اهالی مسکو شاهد صحنه ی عینی در میدان سرخ بودند. جسد مومیائی شده ی استالین را از مقبره لنین بیرون کشیدند و در گوشه ای در پای دیوار کرملین دفن
                   کردند و مقبره لنین هم که آرامگاه لنین-استالین نام گذاری شده بود دوباره به همان نام سابق یعنی آرامگاه لنین خوانده شد. مجسمه های استالین را هم از مسکو و شهرهای دیگر
                    برداشتند و اسامی شهرها و موسساتی که نام استالین خوانده می شد تغییر یافت.</span>`,
    imgSrc: ["53/1.jpg", "53/2.jpg", "53/3.jpg", "53/4.jpg", "53/5.jpg"],
    videoSrc: "53/1.mp4",
  },
  {
    id: 54,
    ruName: "Владимир Илиьич Ленин",
    faName: "ولادیمیر ایلیچ اولیانف",
    enDate: "",
    faDate: "",
    description: `<span>ولادیمیر ایلیچ اولیانف (لنین) پایه گذار حزب کمونیست و دولت شوروی است. او در 22 آپریل  1870 در سیمبیرسک متولد شد. خانواده ی او از قشر متوسط بودند، پدرش بازرس مدرسه
      و مادرش دختر پزشک بود. با توجه به حساسیت مادر فرزندان مسلط به زبان های خارجی بودند (لنین تسلط کامل به زبان آلمانی داشت، فرانسه را می خواند و صحبت می کرد،
         به نسبت انگلیسی را کمتر می دانست).  او در سال 1887م. با رتبه عالی از دبیرستان فارغ التحصیل شد. در همان سال خانواده لنین به سامارا نقل مکان کردند؛ جایی که وی برای اولین بار
          با آثار مارکسیستی آشنا شد. لنین در سال 1891 وارد دانشکده ی حقوق دانشگاه کازان شد. در همان سال پنج دانشجو به دلیل توطئه در قتل تزار الکساندر سوم به دار آویخته شدند، که
           یکی از آنها برادر لنین، الکساندر ایلیچ اولیانوف، بود. لنین از اعدام برادرش توسط تزار سرتاپا خشم و انزجار شد. بعد از مرگ الکساندر، ولادیمیر هفده ساله با ضجه گفت:«کاری می کنم تاوان این
            کارشان را بپردازند! قسم می خورم!». لنین در دانشگاه به دنبال فعالیت های سیاسی و انجمن دانشجویی غیرقانونی بود و تنها 3 ماه پس از ورودش به دانشگاه به دلیل شرکت در اغتشاشات
             دانشجویی از دانشگاه اخراج شد. وی پس از چند سال در آزمون ورودی دانشگاه سنت پترزبورگ شرکت کرد و در رشته ی حقوق مشغول ادامه تحصیل شد. اولیانف در سال 1895 م.  به خارج از
              کشور رفت و محافل پراکنده مارکسیستی را در "اتحادیه مبارزه برای آزادی طبقه کارگر" متحد کرد. هدف اصلی او سرنگونی استبداد بود. وی در همان سال دستگیر شد و بیش از یک سال را در
               زندان گذراند، سپس به مدت سه سال  به سیبری، به روستای شوشنسکویه تبعید شد. لنین در آنجا با کروپسکایا که یکی از تبعیدی های پرونده "شورای مبارزه ..." در پترزبورگ بود، ازدواج کرد، که تا آخر
                عمر دستیار و همفکر لنین باقی ماند. در تبعید لنین این فرصت را یافت که با سایر تبعیدیان ارتباط برقرارکند و در غنی ترین کتابخانه کراسنویارسک کار کند؛ جایی که توانست بیش از 30 مقاله
                 بنویسد. لنین پس از تبعید همچنان به  فعالیت های انقلابی خود در داخل و در خارج از روسیه ادامه داد. در پایان اکتبر 1917 ، لنین به طور غیرقانونی از ویبورگ به پتروگراد رسید، دستور
                  حمله به کاخ زمستانی را داد و انقلاب اکتبر شکل گرفت و پس از آن برای نخستین بار به عنوان  رهبر رسمی حزب سوسیال دموکرات کارگری روسیه 
                  (<span class="link-to-content" onclick="goToPage(35)">بلشویک</span>) سخنرانی کرد. با وجود از بین رفتن دولت موقت در انقلاب اکتبر و پیروزی بلشویک ها، اما تا سال 1922 جنگ داخلی بین
                   طرفداران انقلاب ("قرمز-<span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span>") و مخالفان
                    آن ("سفید- <span class="link-to-content" onclick="goToPage(34)">منشویک ها</span>") ادامه داشت. جنگ به یک فاجعه ملی تبدیل شد، جان میلیون ها نفر را گرفت و کشور را در هرج و مرج و فقر فرو
                     برد. در طول جنگ داخلی در روسیه ، لنین شخصا آغازگر و یکی از سازمان دهندگان اصلی سیاست "ترور قرمز" بود. بر اساس این سیاست لنین دستور اعدام دسته
                      جمعی تمامی مخالفان آرمان های انقلاب و تبعید افراد غیرقابل اعتماد اردوگاه های کار اجباری را صادر می کرد. در جولای سال 1918 دستور تیرباران تزار
                       <span class="link-to-content" onclick="goToPage(48)">نیکلای دوم</span> و خانواده اش را صادر کرد. در ماه می 1922، لنین
                        به شدت بیمار شد. وی کمی پیش از مرگش نگران مسئله جانشینی خود شده بود. گرچه او هرگز جانشینی برای خود تعیین نکرد، آشکارا به حزب هشدار داد
                         که نمی خواهد <span class="link-to-content" onclick="goToPage(53)">استالین</span> جانشین او شود. لنین در نامه ای به حزب که حکم آخرین وصیت او را داشت، با هشدار در مورد شیوه های
                          کار استالین خواستار برکناری این مرد گرجستانی از سمت دبیرکلی حزب شده بود. اکثر اعضای حزب هرگز از وصیت لنین آگاه نشدند و هنگامی که این نامه در گردهمایی خصوصی کمیته مرکزی قرائت شد، لِف
                           کامنت و گریگوری زینویف که از به حکومت رسیدن تروتسکی نظامی قدرتمند در هراس بودند،  با چشم بستن بر روی جاه طلبی ها و قدرت 
                           <span class="link-to-content" onclick="goToPage(53)">استالین</span>، هشدار لنین را نادیده گرفتند و به
                            حمایت از <span class="link-to-content" onclick="goToPage(53)">استالین</span> پرداختند. لنین پس از دومین سکته اش به منطقه گورکی نقل مکان کرد و تا آخرین سال های زندگی خود
                             را در آنجا گذراند. در ژانویه 1924، وضعیت سلامتی لنین ناگهان به شدت وخیم شد و وی در 21 ژانویه 1924 پس از سومین سکته اش درگذشت. مقبره ی او به همراه جسد مومیایی  شده اش در میدان
                              سرخ قرار دارد.</span>`,
    imgSrc: [
      "54/1.jpg",
      "54/2.jpg",
      "54/3.jpg",
      "54/4.jpg",
      "54/5.jpg",
      "54/6.jpg",
      "54/7.jpg",
      "54/8.jpg",
    ],
    videoSrc: "54/1.mp4",
  },
  {
    id: 55,
    ruName: "Кутузов",
    faName: "میخاییل ایلاریوناویچ کوتوزف",
    enDate: "",
    faDate: "",
    description: `<span>میخاییل ایلاریوناویچ کوتوزف فرمانده بزرگ جنگ و فرمانده کل ارتش روسیه در طول جنگ میهنی 1812 است. او در طول زندگی اش پیروزی های بسیاری کسب کرده است که مشهورترین آنها
     نبرد <span class="link-to-content" onclick="goToPage(5)">بارادینو</span> بوده است. کوتوزف در سال 1747م. در سنت پترزبورگ متولد شد. او تحصیلات ابتدایی خود
      را در خانه گذراند و در سال 1759م. وارد مدرسه نظامی توپخانه – مهندسی دربار شد. وی در سال 1761 فارغ التحصیل شد و به زودی  سمت فرماندهی پیاده نظام را دریافت کرد.
       در سال 1770م.  وی را به ارتش رومیانتسف  منتقل کردند. کوتوزوف همراه این ارتش در جنگ با ترکیه شرکت کرد و در سال 1771 م. برای کسب موفقیت های فراوان به درجه سرهنگی ارتقا یافت
       . وی در سال 1772م. به ارتش دوم دالگاروکی درکریمه منتقل شد و در یکی از نبردهایش مجروح شد و برای درمان به اتریش فرستاده شد. کوتوزف پس از بازگشت به
        روسیه در سال 1776م. دوباره وارد خدمت نظام شد و به زودی درجه سرلشکری را دریافت کرد. سرلشکر کوتوزف در سال 1774 م. در یکی از نبردهایش از ناحیه ی
         چشم چپ مجروح شد اما برخلاف تصور عموم بینایی خود را از دست نداد. او در جنگ روسیه و لهستان شرکت کرد. وی پس از مرگ 
         <span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span> نیز در دربار <span class="link-to-content" onclick="goToPage(43)">پاول اول</span> باقی
           ماند و درسال های 1798 تا 1802 م. به عنوان فرمانده در لیتوانی، <span class="link-to-content" onclick="goToPage(21)">سنت پترزبورگ</span>، ویبورگ و
           فنلاند خدمت کرد. در طول جنگ میهنی 1812م. الکساندر اول کوتوزوف را به عنوان فرمانده کل نیروهای ارتش  روسیه منصوب کرد. استراتژی عالی وی در
            طول نبردهای <span class="link-to-content" onclick="goToPage(5)">بارادینو</span> و تاروتینسکی موجب شکست ارتش ناپلئون شد.کوتوزف در 16 آپریل 1813 درگذشت و درکلیسای جامع
             کازان واقع در شهر سنت پترزبورگ به خاک سپرده شد.</span>`,
    imgSrc: ["55/1.jpg", "55/2.jpg", "55/3.jpg", "55/4.jpg"],
    videoSrc: "55/1.mp4",
  },
  {
    id: 56,
    ruName: "Екатерина Великая",
    faName: "کاترین کبیر",
    enDate: "",
    faDate: "",
    description: `<span>کاترین کبیر یکی از شهبانوهای بزرگ و مشهور روسیه است. او که دختر یک نظامی آلمانی درباری بود در سن 15 سالگی به روسیه آورده و به عنوان همسر پتر سوم ولیعهد پترکبیر انتخاب شد. کاترین
     به سرعت زبان روسی را آموخت  و با رسم و رسوم روس ها آشنا شد. او به واسطه ی علاقه زیاد به مطالعه به زبان های مختلف، و دانش گسترده ای به دست آورد. در
      سال 1762 م. درباریان ناراضی از حکومت پتر سوم کودتا کردند، و با کشته شدن وی، کاترین بر تخت نشست. کاترین اصلاحاتی که پترکبیر آغاز کرده بود را ادامه داد و
       دگرگونی های فراوانی را در روسیه به وجود آورد. در زمان سلطنت کاترین، قلمرو روسیه در غرب و جنوب به طور قابل توجهی افزایش یافت وکریمه و کل اوکراین به روسیه اضافه شدند.
        سه دشمن اصلی امپراتوری - ترکیه ، سوئد و لهستان - توسط ارتش و نیروی دریایی روسیه شکست خوردند. او اهمیت زیادی برای تحصیل و با سواد شدن جامعه قائل بود و
         عقیده داشت که برای جلوگیری از جنایات باید روشنگری در بین مردم گسترش یابد و مطمئن ترین راه برای بهتر شدن مردم تربیت صحیح کودکان است. در زمان کاترین مدارس جدیدی افتتاح شد،
          کارخانه ها و کارگاه های جدید شروع به کار کردند، از متخصصان حوزه های مختلف دانش و صنایع دستی برای کار در روسیه دعوت شد.کاترین از نظر زندگی شخصی بی عیب و نقص
           نبود، اما او در اطراف خود افراد با استعدادی را جمع کرده بود،که کارهای مفید زیادی برای روسیه انجام داده بودند.کاترین در زمان به قدرت رسیدن وضعیت روسیه را
            این گونه ارزیابی کرد: وضعیت مالی رو به وخامت است، ارتش 3 ماه حقوق نگرفته است و وزارت جنگ غرق در بدهی است ، تجارت رو به افول است زیرا بسیاری ازشاخه
             های آن به صورت انحصاری واگذار شده است، در اقتصاد ایالتی نظام درستی وجود ندارد، مبلغان مذهبی از این که زمین هایشان را گرفته اند، ناراضی
              اند و قوانین تنها درصورتی اجرا می شوند که به نفع افراد مهم باشند.

    او اهداف خود را به شرح ذیل تنظیم کرد:1) آموزش افراد جامعه؛ 2) ایجاد نظم در ایالت ها و لزوم اجرای قوانین و مقررات یکسان؛ 3) تشکیل نیروی پلیس خوب و
     دقیق در ایالت ها؛ 4) توسعه ی دولت و شکوفایی بخش های مختلف؛ 5) ایجاد دولتی که در انجام امور داخلی خود سخت  گیر و در عین حال برای کشورهای همسایه قابل احترام است.
    روسیه در طی 34 سال سلطنت کاترین دوران طلایی را گذراند و به شرایط ایده آل رسید. از جمله مهمترین دستاوردهای این دوران می توان به موارد زیر اشاره کرد: 1
    ) تقویت امپراتوری روسیه (به گفته ی هم عصران، امپراتوری روسیه به چنان نفوذ و قدرتی دست یافت که بدون اجازه آن در اروپا، حتی یک توپ نیز
         شلیک نمی شد)؛ 2) دو برابر شدن جمعیت این کشور (تقریباً از 19 به 36 میلیون نفر)؛ 3) ساخت 144شهر ؛ 3) چهاربرابرشدن خزانه
          داری دولت؛ 4)  فراهم شدن امکان صادرات نان برای اولین بار؛ 5)  چهار برابر شدت قلمرو روسیه با فتح کل ساحل دریای سیاه و الحاق داوطلبانه ی اوستیا؛ 6) ساخت و توسعه ی مدارس؛
           7) آغاز توسعه آموزش زنان و 8) تبدیل آکادمی علوم روسیه به یکی از برجسته ترین آکادمی های اروپا. اما زندگی سرف ها کماکان در زمان کاترین دچار مشکل بود. کاترین اشراف
            را بخاطر کشیدن کار بیش از حد از سرف هایشان که از عوامل اصلی شورش های دهقانی بود، مورد انتقاد قرار می داد. ملکه در 6 نوامبر 1796 بر اثر سکته
             مغزی درگذشت. مقبره ی وی درکلیسای قلعه ی پتروپاول شهر  
             <span class="link-to-content" onclick="goToPage(21)">سنت پترزبورگ</span>
             واقع شده است. درگذشت وی با پایان عصر اصلاحات همراه بود
              و پس از آن برای شصت سال، رهبران روسیه بیش تر به حفظ سنت ها متعهد ماندند تا رودررویی با مشکلاتی که کشور را فقیر نگه می داشت. 
    </span>`,
    imgSrc: ["56/1.jpg", "56/2.jpg", "56/3.jpg"],
    videoSrc: "56/1.mp4",
  },
  {
    id: 57,
    ruName: "Русь",
    faName: "روس باستان",
    enDate: "",
    faDate: "",
    description: `<span>روس باستان اصطلاحی است که به دو دوره ی تاریخی حکومت روس کی یفی و مسکو اشاره دارد.
     روس کی یفی نام دولت قرون وسطایی اروپایی بود که در اواخر سده ی نهم میلادی تشکیل شد و پس از حمله ی مغول ها
      از میان رفت. شکل اولیه ی نظام سیاسی روس ها، شاهزاده نشینی نام داشت. مرکزیت این شاهزاده نشینی در ولیکی <span class="link-to-content" onclick="goToPage(46)">نُوگاراد</span> قرار
       داشت. در سال 882 م. وایکینگ ها شهر کی یف را از تصرف خزرها بیرون آوردند و آن را
        پایتخت روس کی یفی نمودند. این دولت در سده ی یازدهم میلاددی به اوج خود رسید و مرزهایش از جنوب تا
         دریای سیاه، از شرق تا رودخانه ی ولگا، از غرب تا پادشاهی لهستان و لیتوانی گسترش یافت. اوج شکوفایی روس کی یفی در
          زمان فرمانروایی ولادیمیر مقدس و پسرش یاراسلاول اول بود که روس ها مسیحیت را پذیرفتند و نخستین قانون به یکی
           از زبان های اسلاوی شرقی به نام روسکایا پراودا پدید آمد. فرمانروایان روس کی یفی از تبار نروژی های باستان و گروهی
            از وایکینگ ها بودند که بر اکثریتی از اسلاوها فرمان می راندند. یکی از نقص های دوران حکومت کی یف عدم وجود نظم
             سیاسی و اجتماعی و ارتباط میان ادوار گذشته و زمان های پس از آن بود که باعث سقوط و انحطاط روس کی فی شد. و
              نهایتا دولت روس کیفی با حملات مغول ها به طور کامل  از میان رفت. تجزیه  اقتدار سیاسی توسط شاهزادگان و از میان رفتن اهمیت تجاری
               کی یف که در مسیر اتصال شرق و غرب قرار گرفته بود مراحل پایانی این دوره را مشخص می سازد. در دوران حملات مغول ها، مسکو شهر کوچکی
                در منطقه ولادیمیر بود. در سال 1325 مسکو به مرکز سراسقفی کلیسای ارتدکس تبدیل شد. از آن زمان به
                 بعد منافع مسکو و کلیسای ارتدکس به شدت در هم آمیخت.کلیسا با بهره گیری از قدرت معنوی و اقتصادی
                  و نیز جایگاه ویژه خود که مورد حمایت مغول ها بود ( زمین های کلیسا و کشیشان از همه ی محدودیت ها و نیز پرداخت مالیات معاف بودند) از اهداف حاکمان مسکو حمایت می کرد.
                   یکی از عوامل مهم در پیشرفت این شهر جایگاه پر اهمیت مسکو در شبکه رودخانه ها بود. سفر از مسکو  به دریاهای بالتیک،
                    سفید، کارا، سیاه و خزر در مسیر آبی صورت می گرفت. همین امر جایگاه مسکو را چه در جنگ و چه در تجارت مستحکم می ساخت.</span>`,
    imgSrc: [],
    videoSrc: "",
  },
  {
    id: 58,
    ruName: "Никита Сергеевич Хорушчев",
    faName: "نیکیتا سرگیویچ خروشچف",
    enDate: "",
    faDate: "",
    description: `<span>نیکیتا سرگیویچ خروشچف سیاستمدار مشهورشوروی است که دوران رهبری او را دوران " ذوب" نامیده اند. وی در سال 1918م. به 
    <span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span> پیوست و در جنگ داخلی شرکت کرد. پس از 2 سال از مدرسه حزب کمونیست فارغ التحصیل شد و در
     رویدادهای نظامی درگرجستان شرکت داشت.  او در 7 سپتامبر 1953 به عنوان دبیر اول کمیته مرکزی حزب 
     <span class="link-to-content" onclick="goToPage(35)">بلشویک</span> انتخاب شد و در سال 1956 پس از تشکیل بیستمین کنگره ی حزب کمونیست رسما حکومت خود را بر شوروی آغاز کرد
     . او در آغاز گزارشی از حکومت <span class="link-to-content" onclick="goToPage(53)">استالین</span> و
      جنایاتش ارائه داد، درحالی که از ارائه گزارش فعالیت هایی که خود در آن دست داشت، خود داری کرد. <span class="link-to-content" onclick="goToPage(58)">خروشچف</span>
       14 سال در دستگاه حکومتی استالین فعالیت کرده بود و طبق نظرمفسران قطعا بسیار متملق بوده است که توانسته است  از سوءظن
        <span class="link-to-content" onclick="goToPage(53)">استالین</span> در امان بماند. زمان حکومت خروشچف با موفقیت های علمی و صنعتی بسیاری برای شوروی همراه بود.
         در سال 1957م. شوروی نخستین موشک های قاره پیمای خود را با موفقیت آزمایش کرد و در ماه اکتبر همین سال نخستین قمر مصنوعی یا «اسپوتنیک» شوروی با یکی از
          همین موشک های نیرومند به فضا پرتاب گردید. وی در 27مارس 1958 به عنوان رئیس شورای وزیران اتحاد جماهیر شوروی منصوب شد. او بازیگر برجسته ای در
           صحنه سیاست خارجی بود و چندین  بار توانست ابتکار عمل برای خلع سلاح همزمان با ایالات متحده و پایان آزمایش های تسلیحات هسته ای را به دست بگیرد. در سال
            1955م. وی از ژنو  و با  آیزنهاور ملاقات کرد. از 15 تا 27 سپتامبر ، وی از ایالات متحده دیدن، و در مجمع عمومی سازمان ملل سخنرانی کرد. سخنرانی روشن و احساسی
             او در تاریخ جهان ثبت شد. در 4 ژوئن 1961 ، خروشچف با  جان اف کندی ملاقات کرد. این اولین و تنها دیدار دو رهبر بود. در دوران حکومت خروشچف  به
              شدت به سمت حمایت از مصرف کننده متمایل شد و به  واسطه ی ناتوانی حکومت در تامین منابع مالی لازم جهت تحقق وعده ها و پرداخت بدهی های خود، بیشتر شهروندان پس انداز خود را
               از دست دادند. از سال 1959 م. جهت تحقق وعده های حکومت در راستای سه برابر کردن تولید گوشت، افرادی که در روستاها زندگی می کردند از نگهداری دام منع شدند و دولت
                احشام شخصی را خریداری کرد. اما در ادامه ناتوانی حکومت در دستیابی به این هدف، علی رغم فشار زیادی که به کشاورزان و دامداران وارد شده بود، به نارضایتی عمومی و امتناع
                 از کار بر روی زمین های اشتراکی وکاهش 50 درصدی تولید غلات انجامید. تشکیل کنگره ی بیست و دوم حزب کمونیست شوروی در اواخر سال 1961م. نقطه ی اوج
                  قدرت و موفقیت دوران حکومت خروشچف بر شوروی بشمار می آید، زیرا چندی پس از پایان کنگره دوران افول قدرت او آغاز می شود و این افول در طول سال های 1962و 1963
                   ادامه می یابد. طی این دوسال شوروی همچنان در مسابقه ی تسخیر فضا و تولید موشک های قاره پیما پیشتاز است، ولی از نظر داخلی شکست طرح های توسعه ی کشاورزی و ادامه ی
                    اختلافات مسلکی با چین، و از نظر سیاست خارجی، بحران روابط آمریکا و شوروی بر سرکوبا و عقب نشینی خروشچف، موقعیت او را روبه روز بیشتر تضعیف می کند.
                     درنهایت با گزارش سوسولف درکنگره ی حزب خروشچف کنارگذاشته شده و برژنف به عنوان دبیراول حزب انتخاب می شود. پس از برکناری از قدرت خروشچف مدتی را در بازنشستگی
                      زندگی کرد و در 11 سپتامبر 1971 درگذشت. وی در قبرستان ناوادویچی شهر مسکو به خاک سپرده شده است.</span>`,
    imgSrc: [
      "58/1.jpg",
      "58/2.jpg",
      "58/3.jpg",
      "58/4.jpg",
      "58/5.jpg",
      "58/6.jpg",
    ],
    videoSrc: "58/1.mp4",
  },
  {
    id: 59,
    ruName: "Александр Ярославич Невский",
    faName: "الکساندر یاروسلاویچ نفسکی",
    enDate: "",
    faDate: "",
    description: `<span>الکساندر یاروسلاویچ نفسکی حاکم و فرمانده ی روسی از خاندان روریکویچ بود. در زمان حکومت او بود که 
    <span class="link-to-content" onclick="goToPage(57)">روسی باستان</span> توانست از استقلال خود در برابر غرب کاتولیک دفاع
     کند. معروف ترین نبرد وی در برابر سوئدی ها <span class="link-to-content" onclick="goToPage(16)">نبرد نوا</span> بود
      که پس از آن لقب نفسکی را دریافت کرد. وی در سال 1247م. پس از مرگ پدرش کنترل <span class="link-to-content" onclick="goToPage(45)">کی یف</span> که توسط
       تاتارها نابود شده بود را به دست گرفت. وی به مدت 6 سال در برابر حملات دشمن دفاع کرد و سپس نووگاراد را به مقصد ولادیمیر ترک و در آنجا
        حکومت کرد. الکساندر یاروسلاویچ نفسکی در تاریخ روسیه نقش اساسی داشته است. او در طول زندگی خود هیچ نبردی را از دست نداد و همیشه مورد علاقه و حمایت
         کلیسای جامع ارتدکس بود. او را می توان یک فرمانده و دیپمات با استعداد توصیف کرد که روس باستان را در برابر بسیاری از دشمنان حفظ کرد و از لشگرکشی تاتارهای مغول جلوگیری
          کرد. او در 14 نوامبر 1263 در گارادتس درگذشت و درشهر ولادیمیر به خاک سپرده شد. در سال 1724 به دستور
           پترکبیر بقایای جسد وی به صومعه الکساندر نفسکی در  <span class="link-to-content" onclick="goToPage(21)">سنت پترزبورگ</span>منتقل
            شد. امروزه خیابان ها و میادین زیادی به یاد او نامگذاری شده است.</span>`,
    imgSrc: ["59/1.jpg", "59/2.jpg", "59/3.jfif", "59/4.jpg"],
    videoSrc: "59/1.mp4",
  },
  {
    id: 60,
    ruName: "Петр I",
    faName: "پتر اول",
    enDate: "",
    faDate: "",
    description: `<span>پتر اول معروف به پتر کبیر تزار روسی و مشهورترین نماینده خانواده رومانف است. پتر اول نوه میخائیل،
     اولین <span class="link-to-content" onclick="goToPage(29)">تزار</span> از سلسله رومانف است. او در
      ده سالگی  به عنوان <span class="link-to-content" onclick="goToPage(29)">تزار</span> انتخاب شد، اما در واقع خواهر
       بزرگترش، سوفیا، برکشور حکومت می کرد. در سال 1689م. قدرت به پتر هفده ساله رسید. او مانند بسیاری از اشراف در خانه دوران تحصیل  را گذراند و در سال 1697م.  به صورت
        ناشناس راهی اروپا شد تا به عنوان یک متخصص فنی ساده با نام پتر میخایلف تحصیل کند. او در رشته علوم دریایی، توپخانه و کشتی سازی تحصیل کرد و برای مدتی در هلند،
         آلمان و انگلستان مشغول به کار شد. در این مدت او همواره به دنبال جذب صنعتگران ماهر برای فعالیت در روسیه بود. او شهر سنت پترزبورگ را بنا نهاد که به مدت دو قرن
          پایتخت روسیه بود. در زمان او جنگ با چارلز دوازدهم پادشاه سوئد که برای تصرف اوکراین آمده بود در گرفت. این جنگ با پیروزی روس ها به پایان رسید و چارلز دروازدهم به ترکیه
           گریخت. در نتیجه این پیروزی، روس ها لهستان را آزاد وکشورهای حوزه ی دریای بالتیک را فتح کردند. در سال 1721م. پتر
            لقب <span class="link-to-content" onclick="goToPage(23)">امپراتور</span> را برگزید و در نتیجه ی آن روسیه به یک امپراتوری تبدیل شد. پتر مرزهای روسیه را
             از شمال دریای بالتیک تا جنوب دریای آزوف گسترش داد. او روسیه را در مسیر اروپایی شدن قرار داد و اصلاحات اقتصادی و اداری  زیادی در کشور ایجاد کرد. اصلاحات پتر به
              تقویت دولت متمرکز کمک کرد. در طول سال های سلطنت وی، تعداد کارخانه ها ده برابر شد؛ سازمان ها و وزارتخانه های مختلفی ایجاد شد؛ نقش کلیسا در تصمیم گیری های دولتی
               کاهش یافت؛ پایه و اساس آموزش نظام مند در روسیه ایجاد و مدارس و موزه های بسیاری افتتاح گردید. در دوران حکومت پتر همه فرزندان اشراف ملزم به گزراندن دوران مدرسه
                شدند و بسیاری از نجیب زادگان برای تحصیل به اروپا اعزام شدند .پوشکین درباره او نوشت: " هم یک دانشگاهی، هم یک قهرمان، هم یک دریانورد و هم یک نجار بود؛
                  او در واقع یک کارگر همیشگی بود که با  تمام روح و جان خود بر تخت سلطنت تکیه زده بود." شخصیت پترگرم و تندخو بود، تجمل را دوست نداشت، در خانه ای متوسط
                   زندگی می کرد، مستبد نبود و مردم عادی را دوست داشت. پتر اول در پنجاه و سه سالگی درگذشت و پس از مرگ او، همسرش کاترین اول ملکه شد.</span>`,
    imgSrc: ["60/1.jpg", "60/2.jpg", "60/3.jpg", "60/4.jpg"],
    videoSrc: "60/1.mp4",
  },
  {
    id: 61,
    ruName: "Алексе́й  Петро́вич Ерме́лов",
    faName: "یرملف",
    enDate: "",
    faDate: "",
    description: `<span>یرملف فرمانده ی روسی در 4 ژوئن 1777 در <span class="link-to-content" onclick="goToPage(47)">مسکو</span> متولد شد. او از خانواده ی اشراف بود و
     دوران کودکی اش را با سرسختی های پدر که سعی می کرد عشق به وطن و وطن پرستی را در او بپروراند، پشت سرگذاشته بود به همین دلیل او از هفت  سالگی حرفه  ی
      نظامی گری را انتخاب کرد. او درطول تحصیل توانایی فوق العاده ای از خود نشان داد و در 17 سالگی در اولین نبرد شرکت کرد. با وجود عناوین افتخاری و افتخاراتی که وی در جنگ های
       مختلف کسب کرده بود، اما پس از آن که <span class="link-to-content" onclick="goToPage(29)">تزار</span> <span class="link-to-content" onclick="goToPage(43)">پاول اول</span>به
        سلطنت رسید، او را از خدمت اخراج و تبعید کرد. اما تزار <span class="link-to-content" onclick="goToPage(4)">الکساندر اول</span> او
         را از تبعید آزاد کرد و به سمت خود بازگرداند. وی در آن زمان مسئول توپخانه بود و نقش بسیار مهمی را در حمله ی ناپلئون به روسیه ایفا کرد
         . در  <span class="link-to-content" onclick="goToPage(6)">جنگ میهنی 1812</span> و نبرد <span class="link-to-content" onclick="goToPage(5)">بارادینو</span> نیز
          درخشید و سعی کرد لطف بزرگ امپراتورا جبران کند و لطف و وفاداری خود را نشان دهد. در سال 1816 م. یرملوف به فرمانداری کل قفقاز گماشته شد. وی در موقعیتی
           این مسئولیت را برعهده گرفت که ضعف نظامی و سیاسی دولت قاجاریه شاه و نایب السلطنه را به وجود قبول مصالحه وا داشته بود و بنابراین فرصت بیشتری برای تحقق برنامه
            خود داشت. از نظر تزار الکساندر یکم، یرملف گزینه ی مناسبی برای اداره متصرفات قفقازی پس از مصالحه با ایران و تثبیت قدرت روسیه در آن سرزمین ها بود. وی فعالیت
             های زیادی برای روسی سازی ایالات الحاقی به روسیه انجام داد. او شورایی از سردمداران و افراد سرشناس هر منطقه تشکیل داد و آنها را «بیگ» و « آقا» خواند و با استفاده
              از شناختی که آنها نسبت به منطقه و آداب و رسوم مردم داشتند توانست نظام اداری، سیاسی و اقتصادی جدیدی در منطقه ی قفقاز ایجاد کند و فرهنگ و شرایط آنها
               را به روسیه که حال خود را یک کشور توسعه یافته ی اروپایی می دانست نزدیک کند. وی در سال 1827 م. به روسیه فراخوانده شد، چرا که
                امپراتور نیکلای اول در قفقاز از او حمایت نمی کرد. وی برای مدت طولانی پست و مقام های عالی را دریافت کرد
                 و در طول جنگ کریمه نیز ژنرال 76 ساله موقعیت افتخاری فرماندهی شبه نظامیان را برعهده داشت. ژنرال یرملوف در تمام جنگ هایی که در طول عمرش در روسیه اتفاق افتاد به
                  عنوان فرمانده ای شجاع و سختگیر وگاهی حتی بی رحم جنگید. الکسی پتروویچ یرملوف در 11 آپریل 1861 درگذشت و طبق وصیت خود درشهر آریول روسیه به خاک سپرده شد.</span>`,
    imgSrc: ["61/1.jpg", "61/2.jpg", "61/3.jpg", "61/4.jpeg"],
    videoSrc: "61/1.mp4",
  },
  {
    id: 62,
    ruName: "Бори́с Фео́дорович Годуно́в",
    faName: "باریس فئوداراویچ گادونُف",
    enDate: "1552-1605",
    faDate: "931-984",
    description: `<span>باریس فئوداراویچ گادونُف تزار و سیاستمدار برجسته روسی که از سال 1598 تا 1605م. بر روسیه حکومت کرد. او در یک خانواده ی ملاک به دنیا آمد. باریس گادونوف پس از
     مرگ پدرش توسط عمویش بزرگ شد و به عنوان یک نجیب زاده تحصیل کرد اما با کتاب مقدس آشنایی نداشت که در آن زمان این نا آشنایی نشانه ی بی سوادی بود.
      وی به لطف حمایت عمویش به کرملین راه پیدا کرد و به همراه وارثان تزاری در دربار ایوان مخوف رشد کرد. او در سن 18 سالگی توانست تبدیل به سمت مسئول
       خواب <span class="link-to-content" onclick="goToPage(29)">تزار</span> دست پیدا کند و از
        زندگی شخصی <span class="link-to-content" onclick="goToPage(29)">تزار</span> مطلع گردد. این درحالی بود که خواهرش نیز با پسر دوم ایوان
         مخوف ازدواج کرد و خانواده ی گادونف توانسته بود به آرامی از نردبان سلسله مراتبی بالا رود. پس از مرگ ایوان مخوف در  سال 1584م. (962ش.)، پسرش فئودورکه از
          قدرت و توان کافی برای حکومت برخوردار نبود، به عنوان تزار انتخاب شد. به این ترتیب در دربار مبارزه شدیدی برای کسب عنوان مشاور اعظم تزارکه با توجه به
           شرایط قدرت واقعی حکومت را در دست می داشت، درگرفت. باریس گادونوف با استفاده از حیله گری و فریبکاری خود توانست به این سمت دست پیدا کند. در نتیجه، از سال 1585 م. (963ش.) 
           در واقع  او برکشور حکومت می کرد. گادونوف از همان زمان برای رسیدن به تاج و تخت پس از مرگ فئودور برنامه ریزی می کرد  تنها مانعی که برای رسیدن به
             این هدف بر سر راه خود می دید، دیمیتری برادر ناتنی فئودور بود که به صورت اسرار آمیزی در سال 1591م.(969ش.) درگذشت. باریس گادونف متهم به مرگ او شد، اما کمیسیون ویژه ایجاد شده
              نتوانست دخالت وی در مرگ کودک را اثبات کند. در این دوره، فعالیت های گادونوف با هدف تقویت همه جانبه دولت مداری انجام شد: اولین بار در روسیه اسقف اعظم انتخاب شد، ساخت و ساز شهرها
               و تاسیسات شهری گسترش یافت. گادونف برای جستجوی دهقانان فراری مهلت تعیین کرد. از این پس برده ها به مدت پنج سال جستجو می شدند و اگر پیدا نمی شدند ، آنها افراد آزاد محسوب
                می شدند. علاوه بر این ، آن دسته از مالکانی که بدون استفاده از سرف ها زمین های خود را کشت می کردند از مالیات معاف شدند. باریس گادونف با پایان دادن به جنگ سه ساله روسیه و
                 سوئد خود را یک دیپلمات با استعداد در سیاست خارجی نشان داد. در همان زمان ، شهرهای روسیه که زمانی به تصرف سوئد در آمده بودند، بازگردانده شدند. در ژانویه 1598 م. (976ش.)، 
                 آخرین <span class="link-to-content" onclick="goToPage(29)">تزار</span> خاندان <span class="link-to-content" onclick="goToPage(12)">روریکویچ</span>، فیودور ایوانوویچ، درگذشت
                  وگادونف خود را <span class="link-to-content" onclick="goToPage(29)">تزار</span> خواند. در اولین گام او تمام تلاش خود را به سمت نزدیک شدن به غرب سوق داد،
                   پزشکان، متخصصان، صنعتگران، دانشمندان،نظامیان و بازرگانان خارجی با دریافت حقوق بالا به دربار راه پیدا کردند و به تدریج  فرهنگ اروپایی به روسیه نفوذ کرد. علی رغم تمام جنبه های
                    مثبت حکومت وی، اما  <span class="link-to-content" onclick="goToPage(18)">دوران دشواری</span> و قحطی زمان او را نمی توان نادیده گرفت؛ دورانی که باعث مرگ میلیون ها نفر شهروند روسی شد
                     و کنترل حکومت را از دست <span class="link-to-content" onclick="goToPage(29)">تزار</span> خارج کرد. موقعیت نامطلوب باریس گادونف با وضعیت سلامتی وی پیچیده شد: او که  از بیماری سنگ
                      مثانه و میگرن شدید رنج می برد، در آخرین روزهای زندگی خود به کسی اعتماد نداشت و بدگمانی زیادی نسبت به اطرافیان در وجودش شکل گرفته بود. در 13 آپریل 1605 (24 فروردین 984) او دچار سکته مغزی
                       شد که  در نهایت باعث مرگ وی گردید. در همان روزکمانداران همسر و پسرگودونوف را خفه کردند و دخترش را به صومعه تبعید کردند. خانواده گادونف در صومعه وارسینفسک ، بدون مراسم تشییع جنازه (بسان
                         افرادی که خودکشی کرده اند) دفن شدند.</span>`,
    imgSrc: ["62/1.jpg", "62/2.jpg", "62/3.jpg"],
    videoSrc: "62/1.mp4",
  },
  {
    id: 63,
    ruName: "Алекса́ндр Серге́евич Грибое́дов",
    faName: "الکساندر سرگیویچ گریبایدف",
    enDate: "1790-1979",
    faDate: "1207-1168",
    description: `<span>الکساندر سرگیویچ گریبایدف شاعر، موسیقی دان، نمایشنامه نویس و دیپلمات درخشان روسی است. در 4 ژانویه (15) 1790 (طبق برخی منابع ، 1795) ، الکساندر سرگئیویچ گریبایدوف در خانواده یک سرگرد بازنشسته در مسکو متولد
     شد. زندگینامه وی پر از راز و رمز است و حتی تاریخ دقیق تولد او نیز مشخص نیست. پدر وی فردی بی سواد بود و بچه ها توسط مادرشان که یک پیانیست مشهور بود، بزرگ شدند.  گریبایدف در دوران کودکی به لطف
       مادرش تحصیلات عالی در خانه دریافت کرد و چندین زبان خارجی می دانست. او در سال 1802 م. (1180ش.) وارد مدرسه شبانه روزی دانشگاه <span class="link-to-content" onclick="goToPage(47)">مسکو</span> شد و
        به دلیل موفقیت های فراوان تحصیلی جوایز فراوانی را دریافت کرد. در دوران دانشجویی به ادبیات روی آورد و همیشه در جلسات ادبی شرکت می کرد. در
         همان زمان، اولین آثار گریبایدف نوشته شد. تصمیم یک مرد جوان تحصیلکرده در انتخاب شغل نظامی بسیار عجیب بود. در سال 1812 م. (1190ش.) با شروع جنگ میهنی، زندگی گریبایدف بسیار تغییر کرد. او به عضویت
          هنگ کنت سالتیکوف درآمد اما الکساندر سرگیویچ نتوانست در جنگها شرکت کند و بازنشسته شد. در سال 1817م. (1195ش.) به خدمت کالج امور خارجه ایالت
           <span class="link-to-content" onclick="goToPage(41)">سن پترزبورگ</span> درآمد. علاقه به ادبیات و تئاتر، گریبایدف را به بسیاری از افراد مشهور نزدیک کرد. فتنه ها و شایعات مربوط
            به جامعه سکولار این دوره از زندگی وی را  تیره و تار کرد. وضعیت متزلزل مالی نویسنده را مجبور به ترک خدمت کرد. از سال 1818 م. (1196ش.) الکساندرسرگییویچ گریبایدف به عنوان منشی
             سفارت ایران انتخاب شد و همزمان زبان ها و ادبیات مربوط به فرهنگ شرق را مطالعه می کرد. وی به عنوان یکی از اعضای اعزامی به ایران در سال 1819م.(1197ش.) به
              خدمت در تبریز ادامه داد. برای مذاکرات موفق با ایرانیان، که در نتیجه آنها موفق شدند سربازان اسیر روسی را آزاد کنند، پاداشی به او اهدا شد. دیپلمات بودن مانع
               از نویسندگی او نمی شد، در همین زمان بود که اولین صفحات کمدی جاودانه "وای از عقل" نوشته شد. وی به دلیل مشارکت در قیام دکابریست ها به شش ماه زندان محکوم شد
               . شکست قیام دکابریست ها و سرنوشت غم انگیز دوستان تاثیر مخربی بر روحیه ی گریبایدف گذاشت. او مرگ خود را پیش بینی کرده و دائماً در مورد آن
                صحبت می کرد. او برای بار دوم در سال 1829م.(1207ش.) به عنوان وزیرمختارروسیه در ایران انتخاب شد. اقدامات جنجال برانگیز او مخصوصا 
                فشارها برای آزاد کردن زن های گرجی که حال مسلمان شده بودند و در ایران ازدواج کرده بودند  و فشارهایی در راستای گرفتن غرامت جنگی از 
                ایران که خارج از توان دولت فتحعلی شاه بود خشم برخی از مردم را برانگیخت و همین مسئله باعث قتل او در تاریخ 30 ژانویه 1829م. 
                شد. در نتیجه ی این قتل دولت فتحعلی شاه برای جلوگیری از جنگ بین دو کشور غرامات و هدایای فراوانی را به دولت روسیه هدیه داد.</span>`,
    imgSrc: ["63/1.png", "63/2.jpg", "63/3.jfif"],
    videoSrc: "63/1.mp4",
  },
  {
    id: 64,
    ruName: "День победы",
    faName: "روز پیروزی",
    enDate: "",
    faDate: "",
    description: `<span>جشن ملی که در روز 9 می برگزار می شود و در گفتار روزمره ی روس ها به "9 می" معروف است. این جشن در 8 می 1945 پایه گذاری شد، روز تسلیم آلمان نازی و پایان
     <span class="link-to-content" onclick="goToPage(6)">جنگ کبیر میهنی</span>. از آن زمان  9 می یکی از محبوب ترین جشن ها  در روسیه، اکراین و بلاروس محسوب می شود. در فرهنگ روسیه این روز را
      روز زنده کردن یاد قهرمانانی که به خاطر آزادی و استقلال سرزمین مادری جنگیدند می دانند. مراسم با شکوه روز پیروزی با راهپیمایی صبحگاهی به سمت یادبودهای شهدا و سربازان گمنام کشته شده توسط
       آلمانی و تقدیم گل و تاج گل آغاز می شود. اصولا افرادی که در جنگ حضور داشتند، در این مکان ها جمع می شوند. بسیاری از آن ها با لباس نظامی قدیمی و
        با نشان و مدال روی سینه خود می آیند و فرزندان و نوه های خود را به همراه می آورند. روز 9 می در بیشتر مواقع یک روز بهاری گرم است و همین
         موضوع باعث می شود اکثر مردم از خانه هایشان بیرون بیایند و در خیابان های آذین بسته شده پیاده روی کنند و در جشن های خیابانی شرکت کنند و ترانه های ملی - میهنی بخوانند.</span>`,
    imgSrc: ["64/1.jpg", "64/2.jpg", "64/3.jpg", "64/4.jpg"],
    videoSrc: "64/1.mp4",
  },
  {
    id: 65,
    ruName: "Отмена крепостного права",
    faName: "لغو نظام ارباب رعیتی",
    enDate: "1861",
    faDate: "1239",
    description: `<span>نظام سرف داری یا برده داری یا همان نظام ارباب رعیتی از دیرباز در کشورهای اروپایی و روسیه رایج بود. در این شرایط روستاییان بر روی زمین های ملاکان و دولت کار
     می کردند و اگر مالکی قصد فروش زمینش را داشت، آنها با زمین فروخته می شدند. الکساندر دوم در زمان پادشاهی پدرش به همراه یک گروه به بازدید از سرزمین های سیبری رفتند و از
      شرایط بد زندگی مردم آگاه شد. او پس از تزارشدن دستور اجرای برنامه ی اصلاحات را صادر کرد و دستور آزادی سرف ها و روستاییان را صادر کرد. اما برنامه ریزی برای این اصلاح
       کار آسانی نبود، دولت نمی توانست تمام آن زمین هایی که مردم بر روی آن کار می کردند و به آنها حس مالکیت داشتند را در اختیار آنها قرار دهد چرا که مورد مخالفت شدید ملاکان قرار می
       -گرفت. در نهایت در سال 1861م.(1239ش.) برنامه اصلاحات اعلام شد. اصلاحات الکساندر دوم موجب آزاد سازی 22536000 نفر شد و به همین دلیل به وی لقب آزادکننده داده شد. اما
        آزادی کامل سرف ها بلافاصله صورت نگرفت و برای آن دوره ی زمانی دو ساله تعیین شد. در این دوره آنها ملزم به انجام وظایف پیشین خود بودند و به همین دلیل به این دوره عنوان "موظفی
         موقتی" اطلاق می شود.کشاورزان آزاد شدند و دولت میزان بسیار کمی  از زمین هایی که بر روی آنها کار می کردند را با شرایط پرداخت اقساط 49 ساله به آنها فروخت. اما خوشحالی مردم از آزادی زیاد
          طول نکشید، چرا که  این طرح نواقص زیادی داشت مردم آثار این تغییر را در زندگی خود احساس نمی کردند. به همین دلیل شورش های فراوانی در گرفت، اما این شورش ها علیه تزار
           نبود، آنها علیه ملاکان شورش می کردند، چرا که معتقد بودند تزار قصد کمک به آنها را داشت، اما آنها اجازه این کار را ندادند. با مرگ الکساندر دوم آزادی کوتاه مدت دهقانان روسیه خاتمه
            یافت و آنها دوباره به رعیت مبدل شدند. بدین ترتیب پس از لغو نظام ارباب رعیتی در روسیه کشاورزان به مهم ترین چیزی که به دنبال آن بودند، یعنی زمین های خودشان، دست
             پیدا نکردند. اما به هر حال پایه های برچیه شدن آن در آینده بنا نهاده شد. الغای سرف داری تنها نخستین گام مورد نیاز برای اصلاح جامعه روسیه بود. مادامی
              که یک فرمانروای خودکامه، یا صاحب تمام قدرت، و یک طبقه کوچک اشراف بر کشور تسلط داشتند، زندگی روس های معمولی تغییر نمی کرد. شورش های دهقانی
               گروه رو به رشد رادیکال های جوان را به طرف اعتقاد به ناگزیر بودن انقلاب سوق داد.</span>`,
    imgSrc: ["65/1.jpg", "65/2.jpg"],
    videoSrc: "65/1.mp4",
  },
  {
    id: 66,
    ruName: "Династии Рю́риковичей",
    faName: "خاندان روریکُویچ",
    enDate: "862-1584",
    faDate: "240-962",
    description: `<span>تاریخ  <span class="link-to-content" onclick="goToPage(57)">روسیه باستان</span>با سلطنت <span class="link-to-content" onclick="goToPage(12)">روریک</span> آغاز می شود
    . در آن زمان، اتحادیه های قبایل اسلاوهای شرقی در قلمرو روسیه دارای تمام نشانه های تشکیل یک دولت مرکزی بودند. از مشهورترین حاکمان آن زمان می توان به  کی که در
     قرن 5 <span class="link-to-content" onclick="goToPage(45)">کی یف</span> را تاسیس کرد و گاستمیسل که در قرن 9 اتحادیه اسلاوها را در اطراف 
     <span class="link-to-content" onclick="goToPage(46)">نووگاراد</span> اداره می کرد، اشاره کرد. در سال 859 م. اهالی 
     <span class="link-to-content" onclick="goToPage(46)">نوگاراد</span> به دنبال اجتناب از درگیری های داخلی از روریک  درخواست کردند که حاکم آنها شود و او از سال
      862 م. در آنجا مستقر شد. در سال 879 م. <span class="link-to-content" onclick="goToPage(12)">روریک</span> درگذشت و قبل از مرگش قدرت
       را به خویشاوند خود آلگ منتقل کرد و پسر کوچکش ایگور را به او سپرد. روس باستان در آن زمان توسط همسایگان جنگجو محاصره شده بود. از شمال قبایل نورمن و از شرق و جنوب خزرها و
        پچنگ ها به آن حمله می کردند. زمانی که قدرت به دست شاهزاده سویتاسلاو ایگورویچ نوه روریک  رسید، وی در راستای سیاست های خود درجهت تقویت روسیه، به جنگ جنگجویان پچنگ پرداخت و توانست
         آن ها را شکست دهد. در زمان سلطنت پسرسویتاسلاو، شاهزاده ولادیمیر مقدس، مسیحیت به عنوان دین رسمی معرفی شد. در
          زمان حکومت او آموزش و پرورش گسترش یافت و معابد ساخته شدند. وی که از حاکمان بزرگ روسیه باستان به شمار می رود، فعالیت های چشمگیری از جمله تثبیت و تقویت جایگاه این کشور، تاسیس شهرهای جدید و پایه گذاری
           دین و فرهنگ مسیحیت در این سرزمین را انجام داد. روس باستان در دوران فرمانروایی پسر ولادیمیر مقدس، یاراسلاو حکیم، به رفاه بی-سابقه
            ای دست یافت و روابط بین المللی آن تقویت شد. یکی از بزرگترین رویدادها در زمان حکومت یاروسلاو حکیم ایجاد مجموعه قوانین "حقیقت روسیه" بود. وی قبل از مرگش در سال 1054 م. قلمرو فرمانروایی خود را بین پنج پسر و نوه اش 
            <span class="link-to-content" onclick="goToPage(36)">ولادیمیر ماناماخ</span> تقسیم کرد. در نتیجه <span class="link-to-content" onclick="goToPage(45)">کی یف</span> اهمیت
             اصلی خود را از دست داد، قلمروهای مستقل پدید آمدند که هر کدام توسط یکی از افراد خاندان روریکوویچ اداره می شد. در سال  1147 م. یوری دالگاروکی پسر ولادیمیر ماناماخ مسکو را بنا نهاد. یک قرن بعد در
              سال 1223م. <span class="link-to-content" onclick="goToPage(15)">حمله ی مغول</span> صورت گرفت و روس ها شکست سختی را متحمل شدند. مغول ها بیش از 200 سال در روسیه ماندند و
               این کشور را تضعیف کردند. آلمانی ها و سوئدی ها با بهره گیری از ضعف روسیه، همزمان از شمال به مرزهای این کشور حمله می کردند و نبردهایی از جمله 
               <span class="link-to-content" onclick="goToPage(16)">نبرد نوا</span> درگرفت. پس از گذشت 200 سال و با روی کار آمدن ایوان سوم و افزایش قدرت پایتخت پادشاه از دادن خراج به
                مغول ها امتناع کرد. خان مامای (خان مغول) تصمیم گرفت روس ها را مجازات کند و در سال 1378م. لشکر خود را به روسیه فرستاد، اما آنها توسط شاهزاده
                 <span class="link-to-content" onclick="goToPage(47)">مسکو</span> دیمیتری ایوانوویچ شکست خوردند. در سال 1380 م. خان مامای در راس یک ارتش جدید و حتی
                  بزرگ تر به روسیه حمله کرد و <span class="link-to-content" onclick="goToPage(17)">نبرد کولیکوف</span> اتفاق افتاد و در نتیجه ارتش خان مامای به کلی شکست خورد. وحدت کامل سرزمین های روسیه به دوران
                   واسیلی سوم (1505-1533م.) برمی گردد. سپس سرزمین پسکوف و امپراتوری ریازان به مسکو پیوستند و شهر باستانی اسمولنسک از لیتوانی باز پس گرفته شد. در دوران زمامداری این خاندان وسعت
                    سرزمین روسیه افزایش قابل توجه ای یافت. نقطه ی اوج این گسترش در زمان ایوان سوم اتفاق افتاد که با پنج برابر شدن قلمرو روسیه همراه بود. انتخاب نشان عمومی
                     عقاب دو سر، وضع قوانین یکسان برای کل کشور و ایجاد قانون برده داری از دیگر اتفاقات مهم دوران حکومت ایوان سوم هستند. سلسله ی روریکویچ پس از مرگ
                      <span class="link-to-content" onclick="goToPage(20)">ایوان مخوف</span> به پایان رسید و <span class="link-to-content" onclick="goToPage(67)">خاندان رومانف</span>
                       سلطنت خود را بر امپراتوری روسیه آغاز کردند.</span>`,
    imgSrc: ["66/1.jpg", "66/2.jpg", "66/3.jpg"],
    videoSrc: "66/1.mp4",
  },
  {
    id: 67,
    ruName: "Рома́новы (Дом Рома́новых)",
    faName: "خاندان رُمانُف ها",
    enDate: "1613-1917",
    faDate: "991-1296",
    description: `<span>سلسله ی رومانف ها در سال 1613م. و با شروع سلطنت میخائیل رومانوف پایه گذاری شد. حکومت رومانف ها بر روسیه با اتفاقات بسیاری از جمله اروپایی شدن این کشور، تبدیل آن به امپراتوری
     (Петр I →)، شکست نیروهای ناپلئون، انحلال <span class="link-to-content" onclick="goToPage(65)">نظام ارباب رعیتی</span>
      همراه بود. سلطنت این خانواده بر روسیه در سال 1917م. با روی کار آمدن بلشویک ها و کناره گیری <span class="link-to-content" onclick="goToPage(48)">نیکلای دوم</span>
       از سلطنت به پایان رسید. تزارها و امپراتور های این خاندان عبارتند از: <span class="link-to-content" onclick="goToPage(11)">میخاییل فئوداراویچ</span>، آلکسی
        میخایلویچ، فئودار آلکسیویچ، سوفیا آلکسیونا، ایوان الکسییویچ، <span class="link-to-content" onclick="goToPage(60)">پتر اول الکسیویچ</span>،
         <span class="link-to-content" onclick="goToPage(56)">کاترین اول</span>، پتر دوم الکسویچ، آنا ایوانونا، آنا لئوپالدونا، الیزابتا پتروونا، پتر سوم، 
         <span class="link-to-content" onclick="goToPage(56)">کاترین کبیر</span>، <span class="link-to-content" onclick="goToPage(43)">پاول اول</span>،
          <span class="link-to-content" onclick="goToPage(4)">الکساندر اول</span>، نیکلای اول،
           الکساندر دوم، الکساندر سوم، <span class="link-to-content" onclick="goToPage(48)">نیکلای دوم</span>. </span>`,
    imgSrc: ["67/1.jpg", "67/2.jpg", "67/3.jpg", "67/4.jpg"],
    videoSrc: "67/1.mp4",
  },
  {
    id: 68,
    ruName: "Па́вел Демитре́евич Цициа́нов",
    faName: "پاول دمیتریویچ سیسیانُف",
    enDate: "1754-1806",
    faDate: "1133-1184",
    description: `<span> ژنرال پاول دمیتریویچ سیسیانف فرمانده اصالتا گرجی نیروهای پیاده نظام روسی است. 
    او از سال 1786م. (1164ش.) به عنوان فرمانده در جنگ ترکیه و روسیه، در سال 1794م. (1174ش.) در قیام لهستان 
    و در سال 1796 م. (1176ش.) در جنگ ایران و روسیه به عنوان دستیار ژنرال زوبُف شرکت 
    داشت. وی در سال 1802 م. (1180ش.) به عنوان استاندار آستراخان انتخاب شد و در سال 1803م. (1181ش.) به دستور 
    <span class="link-to-content" onclick="goToPage(4)">الکساندر اول</span> به منظور پس گرفتن قفقاز از ایران، روانه ی این منطقه شد و این مساله باعث شعله ور شدن 
    آتش جنگ های ده ساله ی ایران و روسیه شد. او در سال 1804 م. (1182ش.) خانات گنجه و چند ایالت گرجستان را به روسیه 
    اضافه کرد. در طول جنگ های ایران و روسیه (1804-1813م.)  (1182- 1191 ش.)در سالهای 1804-1805م. (1182-1183ش.) حملات 
        <span class="link-to-content" onclick="goToPage(1)">عباس میرزا</span> را دفع و چندین شکست را به ارتش ایران تحمیل کرد و خانات شکی و قره باغ را به روسیه اضافه کرد. وی برای
         فتح مناطق مختلف قفقاز کشتار فراوانی ابتدا کشتار فراوانی را به راه می انداخت و سپس  با مذاکره و دادن رشوه به خان های این مناطق آن ها را به تسلیم شدن وگشودن درهای شهر ترغیب می کرد
         . در یکی از همین مذاکرات که به دعوت حسین قلی خان (برای پرداخت رشوه) ، خان باکوکه در آن زمان از توابع ایران بود، انجام شد، به قتل رسید. حسین قلی
          خان و اطرافیانش که از جنگ ها و خونریزی های او ناراحت بودند، سیسیانوف را به این منطقه  دعوت کرده و در فرصتی مناسب او و سربازانش را گلوله باران کردند و
           سر وی را برای فتحعلی شاه به تهران فرستادند.</span>`,
    imgSrc: ["68/1.jfif", "68/2.gif", "68/3.jpg", "68/4.jpg"],
    videoSrc: "68/1.mp4",
  },
  {
    id: 69,
    ruName: "Большой террор (Ежовщина)",
    faName: "تصفیه ی خونین",
    enDate: "1936-1938",
    faDate: "1314-1316",
    description: `<span>اصطلاحی است که به ترورها و کشتار سال های 1936-1938م. میلادی اطلاق می شود. در این دوران طبق دستور <span class="link-to-content" onclick="goToPage(53)">استالین</span> تمامی
     افرادی که به گونه ای مخالف حزب بودند محکوم به قتل می شدند. به واسطه ی این تصمیم بسیاری از افراد سیاسی مهم، دانشمندان و متخصصان و عامه ی مردم پس از محکومیت در دادگاه 
     های ساختگی کشته می شدند.</span>`,
    imgSrc: ["69/1.jpg", "69/2.jpg", "69/3.jpg", "69/4.jpg"],
    videoSrc: "69/1.mp4",
  },
  {
    id: 70,
    ruName: "Ба́рщина",
    faName: "بارشینا",
    enDate: "",
    faDate: "",
    description: `<span>بارشینا مالیاتی بود که به صورت کار پرداخت می شد. برای پرداخت بارشینا دهقان موظف بود که برای صاحب زمین کار کند، و این
     کار صرفا کار روی زمین کشاورزی نبود، ممکن بود کار در خانه ی صاحب زمین نیز باشد. در برخی منابع آمده است که بارشینا سه روز
      در هفته بوده است، به طوری که دهقان می توانست سه روز دیگر را برای خود کار کند و یک
       روز را نیز به عبادت بپردازد. در دوره ی سلطنت <span class="link-to-content" onclick="goToPage(56)">کاترین</span> تعداد روزهای بارشینا به بیش از سه روز افزایش یافت.</span>`,
    imgSrc: ["70/1.jpg"],
    videoSrc: "70/1.mp4",
  },
  {
    id: 71,
    ruName: "Обро́к",
    faName: "آبرُک",
    enDate: "",
    faDate: "",
    description: `<span>ابروک مالیاتی بود که به صورت نقدی یا جنسی پرداخت می شد.در دوره ی کاترین میزان اُبروک به شدت افزایش یافت. در دهه  ی 1760 م. اُبروک معمولا بین یک
    تا دو روبل برای هر مرد بود. این مبلغ به طور مداوم افزایش یافت و در دهه 1770م. به دو تا سه روبل، در دهه ی 1780م. به چهار و در دهه ی 1790م. به پنج روبل رسید.</span>`,
    imgSrc: ["71/1.jpg", "71/2.jpg", "71/3.jpg"],
    videoSrc: "71/1.mp4",
  },
  {
    id: 72,
    ruName: "Восстание Пугачева",
    faName: "شورش پوگاچف",
    enDate: "1773",
    faDate: "1152",
    description: `<span>یکی از شورش های دهقانی دهه 1770 روسیه بوده است که به رهبری یلمان پوگاچف  صورت گرفت. وی قزاقی بود که درکنار رودخانه ی دُن درجنوب روسیه بزرگ شده بود. 
    از زمان قتل پتر شوهر کاترین، در سال 1762م. (1114ش.)، شش مرد دیگر مدعی شده بودند که تزار بحق هستند. هرکدام داستانی داشتند که چگونه به طور معجزه آسایی از مرگ نجات پیدا کردند. هفتمین نفر یلمان پوگاچف بود که در سپتامبر 1773م. (1152ش.) شورش خود را تنها با 80  نفر شروع کرد، اما به مرور دهقانان، مومنان قدیمی که به دنبال زنده کردن
     سنت های خود بودند و غیرروس هایی که سرزمینشان به روسیه اضافه شده بود اما آنها هنوز فرمانروایی <span class="link-to-content" onclick="goToPage(47)">مسکو</span> را نپذیرفته بودند
      به حمایت از او پرداختند. سربازان پوگاچف دارای سازماندهی ضعیف و فاقد انضباط بودند. آن ها در مسیر خود از یک روستا به روستای دیگر حمله می بردند، خانه هارا به آتش می
       کشیدند، به زنان تجاوز می کردند و مردم را به قتل می رساندند. دهقانان از بی رحمی هایی که او در مقابل اشراف داشت خوشحال بودند، اما امنیت خود را نیز در خطر می
        دیدند. کاترین سربازانش را برای فرونشاندن این شورش اعزام داشت و در سال 1774م. (1153ش.) موفق به سرکوب قیام پوگاچف شد. وی پس از
         دستگیری به <span class="link-to-content" onclick="goToPage(47)">مسکو</span> برده شده و به دستور <span class="link-to-content" onclick="goToPage(56)">کاترین</span>گردن زده
          شد. مرگ پوگاچف به اقدامات دولت برای سرکوب شورش پایان نداد. در میدان اصلی هر روستایی یک سکوی اعدام کارگذاشته شده بود. اعدام، شلاق و تبعید جزای هر گونه فعالیت شورشی بود
           و بردن نام پوگاچف ممنوع بود. سرکوب این ناآرامی اثربخش واقع شد و در 21 سال آخر سلطنت کاترین، تا هنگام مرگش در سال 1796م. (1175ش.) شورش ها بسیار کم شد شاید در سال یک شورش.
   </span>`,
    imgSrc: ["72/1.jpg", "72/2.jpg", "72/3.jfif", "72/4.jfif", "72/5.jpg"],
    videoSrc: "72/1.mp4",
  },
  {
    id: 73,
    ruName: "Тильзи́тский мирный договор",
    faName: "پیمان تیلسیت",
    enDate: "1807",
    faDate: "1186",
    description: `<span>معاهده ای است که بین <span class="link-to-content" onclick="goToPage(4)">الکساندر اول</span> و ناپلئون در سال 1807 م. (1186ش.) بسته می
     شود. طبق این معاهده فرانسه و روسیه متعهد شدند که از یکدیگر در برابر کشور سوم حمایت کنند و با بسته شدن این پیمان عملا فرانسه معاهده ی فین کن اشتاین را
      که طبق آن متعهد به حمایت نظامی از ایران بود و کمتر از سه ماه قبل از آن تاریخ امضا شده بود را زیر پا گذاشت.</span>`,
    imgSrc: ["73/1.jfif", "73/2.jfif", "73/3.jfif", "73/4.jpg"],
    videoSrc: "73/1.mp4",
  },
  {
    id: 74,
    ruName: "Русско-японская война",
    faName: "جنگ روسیه و ژاپن",
    enDate: "1904-1905",
    faDate: "1282-1283",
    description: `<span>جنگ میان روسیه و ژاپن که بر سر منطقه ی پورت آرتور و شبه جزیره ی منچوری (شبه جزیره لیائودونگ) 
    درگرفت. پیش زمینه ی این جنگ مربوط به نخستین جنگ چین و ژاپن می باشد که در سال های 1895-1894م. 
    (74-1273ش.) اتفاق افتاد. پس از آن جنگ ژاپن شبه جزیره لیائودونگ را به دست آورد و روسیه به عنوان متحد فرانسه 
    و آلمان، ژاپن را مجبور به ترک این قسمت از خاک چین کرد. در سال 1896، پیمان روسیه و چین در مورد اتحاد 
    دفاعی علیه ژاپن منعقد شد. چین به روسیه امتیاز اعطای راه آهن از چیتا به ولادیواستُک از طریق منچوری (شمال شرقی چین) را داد. 
    خط راه آهن معروف به راه آهن چین شرقی در سال 1897 م. (1276ش.) شروع به ساخت کرد. در سال 1898 روسیه 
    قسمت جنوبی شبه جزیره لیائودونگ ، به اصطلاح منطقه پورت آرتور را به مدت 25 سال اجاره کرد. از مارس 1898، 
    این بندر به پایگاه ناوگان روسیه تبدیل شد و همین امر منجر به تشدید تناقضات بین ژاپن و روسیه شد. 
    در نتیجه در سال 1905- 1904 (1283-1282ش.) حمله ی ژاپن به ناوگان دریایی روسی مستقر در منطقه ی پورت آرتور منچوری 
    آغاز شد. ژاپن توانست با پشتیبانی نظامی انگلستان نیروهای روسی را شکست دهد، شکستی که دور از انتظار نیروهای روسی بود.</span>`,
    imgSrc: [
      "74/1.jpg",
      "74/2.jpg",
      "74/3.jpg",
      "74/4.jpg",
      "74/5.jpg",
      "74/6.jpg",
      "74/7.jpg",
      "74/8.jpg",
    ],
    videoSrc: "74/1.mp4",
  },
  {
    id: 75,
    ruName: "Октябрьская револю́ция",
    faName: "انقلاب اکتبر",
    enDate: "1917",
    faDate: "1296",
    description: `<span>در اکتبر 1917م. (1296ش.) نارضایتی از دولت، جنگ و گرسنگی مردم را به خیابان های سنت  پترزبورگ کشاند.کارگران وسربازان شورایی تشکیل دادند که
     به دنبال قدرت سیاسی بود. ارتش به اعتصاب کنندگان پیوست. تزار <span class="link-to-content" onclick="goToPage(48)">نیکلای دوم</span> از سلطنت کناره گیری کرد.کشور به
      دست <span class="link-to-content" onclick="goToPage(44)">دولت موقت</span> افتاد و خانواده سطنتی دستگیر شده و به شهر توبولسک فرستاده شدند. علاوه بر دولت موقت، کمیته
       انقلابی نظامی پتروگراد نیز وجود داشت که توسط <span class="link-to-content" onclick="goToPage(35)">بلشویک ها</span> به رهبری <span class="link-to-content" onclick="goToPage(54)">لنین</span> و تروتسکی هدایت می شد و گروه های مسلح گارد سرخ را سازمان می داد. در 25 اکتبر (7 نوامبر طبق تقویم جدید) 1917 (16
         آبان 1296) ، با تصمیم کمیته پتروگراد ، گارد سرخ در پتروگراد  به دنبال وقوع <span class="link-to-content" onclick="goToPage(48)">جنگ داخلی</span> پل ها ، ایستگاه های قطار، اداره پست، تلگراف را ضبط و به
          کاخ زمستانی، محل اقامت <span class="link-to-content" onclick="goToPage(44)">دولت موقت</span>، حمله کرد. به واسطه ی این حمله اعضای دولت دستگیر شدند و بلشویک ها ایجاد دولت جدید
           - شوراهای نمایندگان کارگران ، دهقانان و سربازان - را اعلام کردند.</span>`,
    imgSrc: [],
    videoSrc: "",
  },
];

export default data;
